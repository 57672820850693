/* calltouch requests */
Element.prototype.matches||(Element.prototype.matches=Element.prototype.matchesSelector||Element.prototype.webkitMatchesSelector||Element.prototype.mozMatchesSelector||Element.prototype.msMatchesSelector),Element.prototype.closest||(Element.prototype.closest=function(e){for(var t=this;t;){if(t.matches(e))return t;t=t.parentElement}return null});
var ct_get_val=function(form,selector){if(!!form.querySelector(selector)){return form.querySelector(selector).value;}else{return '';}}
document.addEventListener('click', function(e) {
    var t_el = e.target;
    if (t_el.closest('#appointment [type="submit"],#callback-modal [type="submit"],#convenient-time .btn-blue.btn-blue--big, .specialist-find__container .specialist-find__container-box-button.cb-btn, .specialist-order .btn-blue.specialist-order-card__block-price-btn,.modal__content-items .btn.cb-btn,.modal__content-items .modal__content-item .btn-send, .cb-ref .cb-btn')){ try {
        var f = t_el.closest('.modal__content-items, #callback-modal, #appointment, #convenient-time, .specialist-find__container,.specialist-order');
        var fio = '';
	var form_name_hidden_tag_val = ct_get_val(f,'input[name="form_name"]');
	var specialist_hidden_tag_val = ct_get_val(f,'input[name="specialist_fio"]');
	var say_to_operator = [];
	if(!!specialist_hidden_tag_val){ 
		say_to_operator = [{"name": "Специалист", "value": specialist_hidden_tag_val}];
	}

        var name = ct_get_val(f,'input[name="name"],input[placeholder="Имя"]');
        var lastname = ct_get_val(f,'input[name="surname"]');
        if (!!name && !!lastname){ fio = name + ' ' + lastname; }
        else {
            if (!!name){ fio = name; }
            if (!!lastname){ fio = lastname; } }
        var form_name = f.getAttribute('id');
        var phone = ct_get_val(f,'input[type="tel"]');
        var email = ct_get_val(f,'input[type="email"],input[name="email"]');
        var sub = 'Заявка с ' + location.hostname;
        var ct_data = {
            fio: fio,
            phoneNumber: phone,
            email: email,
            subject: sub,
            requestUrl: location.href,
            sessionId: window.call_value
        };
        var post_data = Object.keys(ct_data).reduce(function(a,k){if(!!ct_data[k]){a.push(k+'='+encodeURIComponent(ct_data[k]));}return a},[]).join('&');
        var site_id = '57644';
        var CT_URL = 'https://api.calltouch.ru/calls-service/RestAPI/requests/'+site_id+'/register/';
	var ct_vidget_key = '';
        var ct_valid = !!phone && !!fio&&!!ct_vidget_key;
	if(form_name_hidden_tag_val == 'Быстрая запись на сеанс'){
		ct_vidget_key = 'form_header_record';
		ct_valid = !!phone && !!fio&&!!ct_vidget_key;

	}else if(form_name_hidden_tag_val == 'Заказать обратный звонок'){
		ct_vidget_key = 'call_me_header';
		ct_valid = !!phone && !!fio&&!!ct_vidget_key;

	}else if(form_name_hidden_tag_val == 'Не нашли удобное время?(Заказ)'){
		ct_vidget_key = 'comfort_time';
        	var ct_valid = !!phone && !!email&&!!ct_vidget_key;

	}else if(form_name_hidden_tag_val == 'Помощь в подборе специалиста'){
		ct_vidget_key = 'correct_specialist';
        	var ct_valid = !!phone && !!ct_vidget_key;

	}else if(form_name_hidden_tag_val == 'Форма 4 шага (основная)'){
		ct_vidget_key = 'for_step';
		ct_valid = !!phone && !!fio&&!!ct_vidget_key;

	}else if(form_name_hidden_tag_val == 'Не нашли удобное время? (Карточка спеца)'){
		ct_vidget_key = 'no_time_needed';
        	var ct_valid = !!phone && !!email && !!ct_vidget_key;

	}
	const lastFourCharacters = phone.slice(-5); 
	if(!fio){fio = 'Не указано'}
	console.log(ct_data,ct_valid,'ct_vidget_key:',ct_vidget_key, 'say_to_operator:',say_to_operator);
	if (!!phone && ct_valid && !!ct_vidget_key){
		var phone_ct = phone.replace(/[^0-9]/gim, '');
		if (phone_ct!=''){
			if (phone_ct[0] == '8') {phone_ct=phone_ct.substring(1);}
			if (phone_ct[0] == '7') {phone_ct=phone_ct.substring(1);}
			phone_ct= '7' +phone_ct;
			// window.ctw.createRequest(ct_vidget_key, phone_ct, say_to_operator, function(success, data){ console.log(success, data); });
			window.ctw.createRequest(ct_vidget_key, phone_ct, [{"name": "Имя", "value": fio},{"name": "Номер", "value": lastFourCharacters}], function(success, data){ console.log(success, data); } );
		}
	}
	
/*
        if (/appointment/gi.test(form_name)){ ct_valid = !!phone && !!name && !!lastname; }
        if (/callback-modal/gi.test(form_name)){ ct_valid = !!phone; }
        if (/convenient-time/gi.test(form_name)){ ct_valid = !!phone && !!email; }
        console.log(ct_data,ct_valid);
        if (ct_valid && !window.ct_snd_flag){
            window.ct_snd_flag = 1; setTimeout(function(){ window.ct_snd_flag = 0; }, 20000);
            var request = window.ActiveXObject?new ActiveXObject("Microsoft.XMLHTTP"):new XMLHttpRequest();
            request.open("POST", CT_URL, true); request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            request.send(post_data);
        }
*/
    } catch (e) { console.log(e); } }
});
/* calltouch requests*/


let ct_addGoalEventListeners = function () {
    // Записаться кнопка на баннере
    let sendRequestButtons = document.querySelectorAll('[data-modal-open="appointment"][class*="welcome__container-btn"]');

    sendRequestButtons.forEach(sendRequestButton => {
        sendRequestButton.addEventListener('click', () => {
            ct('goal', 'send_request');
        });
    });

    // Клик по ссылке специалисты
    let specialistsLinks = document.querySelectorAll('a[href*="/specialists"].header__navbar-m-nav-item, a[href*="/specialists"].header__navbar-inner-panel-nav-item');

    specialistsLinks.forEach(specialistsLink => {
        specialistsLink.addEventListener('click', () => {
            ct('goal', 'specialists');
        });
    });

    // Клик по кнопке обратный звонок
    let callbackModals = document.querySelectorAll('[data-modal-open="callback-modal"]');

    callbackModals.forEach(callbackModal => {
        callbackModal.addEventListener('click', () => {
            ct('goal', 'callback_click');
        });
    });

    // Клик по номеру телефона в хедере
    let phonesHeader = document.querySelectorAll('.header__navbar-contact-container-bigtext');

    phonesHeader.forEach(phoneHeader => {
        phoneHeader.addEventListener('click', () => {
            ct('goal', 'click_on_number_header');
        });
    });

    // Клик по номеру телефона в футере
    let phonesFooter = document.querySelectorAll('.footer__container-list-text--phone');

    phonesFooter.forEach(phoneFooter => {
        phoneFooter.addEventListener('click', () => {
            ct('goal', 'click_on_number_footer');
        });
    });

    // Клик по номеру телефона в контактах
    let phonesContact = document.querySelectorAll('.contacts__container-content-phone');

    phonesContact.forEach(phoneContact => {
        phoneContact.addEventListener('click', () => {
            ct('goal', 'click_on_number_contacts');
        });
    });

    // Клик по номеру телефона Помощь в подборе специалиста
    let phonesSpecialist = document.querySelectorAll('.contacts__container-content-phone');

    phonesSpecialist.forEach(phoneSpecialist => {
        phoneSpecialist.addEventListener('click', () => {
            ct('goal', 'click_on_number_specialisthelp');
        });
    });

    // Клик на элементе Поиск
    let searchElements = document.querySelectorAll('.header__navbar-search, .filter__search');

    searchElements.forEach(searchElement => {
        searchElement.addEventListener('click', () => {
            ct('goal', 'search');
        });
    });

    // Клик по кнопке "записаться" в хедере
    let appointmentButtons = document.querySelectorAll('[data-modal-open="appointment"][class*="header__button"]');

    appointmentButtons.forEach(appointmentButton => {
        appointmentButton.addEventListener('click', () => {
            ct('goal', 'appointment');
        });
    });

    // Клик по ссылке vk
    let vkLinks = document.querySelectorAll('.social a[href*="https://vk.com/senselife_samara"], .ya-share2__item_service_vkontakte');

    vkLinks.forEach(vkLink => {
        vkLink.addEventListener('click', () => {
            ct('goal', 'vk_click');
        });
    });

    // Клик по ссылке whatsup
    let wahtsupLinks = document.querySelectorAll('.social a[href*="https://wa.me/message/G5PLBVX5GJHOH1"], .ya-share2__item_service_whatsapp');

    wahtsupLinks.forEach(wahtsupLink => {
        wahtsupLink.addEventListener('click', () => {
            ct('goal', 'whatsapp_click');
        });
    });

    // Клик по ссылке telegramm
    let telegrammLinks = document.querySelectorAll('.social a[href*="https://t.me/sense_life_smr"], .ya-share2__item_service_telegram');

    telegrammLinks.forEach(telegrammLink => {
        telegrammLink.addEventListener('click', () => {
            ct('goal', 'tg_click');
        });
    });

    // Клик по видеокарточке
    let videoCutoaways = document.querySelectorAll('[data-modal-open="modal-video"]');

    videoCutoaways.forEach(videoCutoaway => {
        videoCutoaway.addEventListener('click', () => {
            ct('goal', 'cutaway');
        });
    });

    // Клик по ссылке на продокторов
    let prodoctorLinks = document.querySelectorAll('a[href*="https://prodoctorov.ru/"]');

    prodoctorLinks.forEach(prodoctorLink => {
        prodoctorLink.addEventListener('click', () => {
            ct('goal', 'prodoctor_click');
        });
    });
    // Клик на статьи
    let articleBlocks = document.querySelectorAll('.specialist-new__profile .specialist-new__profile-wrap, .specialist-new__profile .specialist-new__profile-m-wrap, .specialist-main__about-container.tabs-body');

    articleBlocks.forEach(articleBlock => {
        articleBlock.addEventListener('click', () => {
            ct('goal', 'article_click');
        });
    });

    // Записаться общая страница
    let recordLinkButtons = document.querySelectorAll('.specialist-new__container-card-price [href*="/order"]');

    recordLinkButtons.forEach(recordLinkButton => {
        recordLinkButton.addEventListener('click', () => {
            ct('goal', 'record_specialist_global');
        });
    });

    // Записаться внутри карточки специалиста
    let enrollLinkButton = document.querySelector('.start-order-btn');

    if (enrollLinkButton)
        enrollLinkButton.addEventListener('click', () => {
            ct('goal', 'record_specialist_on_page');
        });

    // Кнопка Перезвоните мне
    let callbackButton = document.querySelector('.specialist-find__container-box-button.cb-btn');

    if (callbackButton)
        callbackButton.addEventListener('click', () => {
            ct('goal', 'callback_me');
        });

    // Не нашли удобное время
    let convinientTimeLink = document.querySelector('[data-modal-open="convenient-time"]');

    if (convinientTimeLink)
        convinientTimeLink.addEventListener('click', () => {
            ct('goal', 'no_convinient_time');
        });

    // Клик с переходом на карточку специалиста
    let clickOnSpecialistCardElements = document.querySelectorAll('a[href*="/specialists/"]');


    clickOnSpecialistCardElements.forEach(clickOnSpecialistCardElement => {
        clickOnSpecialistCardElement.addEventListener('click', () => {
            ct('goal', 'specialist_card_click');
        });
    });
}


window.addEventListener("DOMContentLoaded", ct_addGoalEventListeners);
