let $wrapBtns = document.querySelectorAll('.specialist-main__about-more');

let navSideOpenClass = 'specialist-main__about-container--open'

$wrapBtns.forEach($btn => {
    let $container = $btn.parentElement.querySelector('.specialist-main__about-container');

    if ($container)
    {
        let $wrapperContainer = $container.querySelector('.specialist-main__about-container-wrapper')

        if ($wrapperContainer)
        {
            let containerHeight = $wrapperContainer.getBoundingClientRect().height;

            $btn.addEventListener('click', (e) => {
                checkContainerOpen($container, containerHeight, e.currentTarget);
            })
        }

    }

});

function checkContainerOpen($container, containerHeight, $btn){
    if(!$container.classList.contains(navSideOpenClass)){
        $container.style.height = containerHeight + 'px';
        $container.classList.add(navSideOpenClass)

        $btn.innerHTML = '<<< Свернуть'
    }else {

        if (window.matchMedia("(min-width: 600px)").matches) {
            $container.style.height = 80 + 'px';
        }
        else{
            $container.style.height = 104 + 'px';
        }

        $container.classList.remove(navSideOpenClass)
        $btn.innerHTML = 'Подробнее >>>'
    }
}


let $wrapControlBtns = document.querySelectorAll('.wrap-control-btn');
$wrapControlBtns.forEach($btn=>{
    let $container = $btn.parentElement.querySelector('.wrap-control-container');

    if ($container)
    {
        let $wrapperContainer = $container.querySelector('.wrap-control-container-wrapper')

        let containerHeight = $wrapperContainer.getBoundingClientRect().height;

        $btn.addEventListener('click', (e) => {
            checkWrapperContainerOpen($container, containerHeight, e.currentTarget);
        })
    }
})

function checkWrapperContainerOpen($container, containerHeight, $btn){
    if(!$container.classList.contains(navSideOpenClass)){
        $container.style.height = containerHeight + 'px';
        $container.classList.add(navSideOpenClass)

        $btn.innerHTML = 'Скрыть Х'
    }else {

        if (window.matchMedia("(min-width: 600px)").matches) {
            $container.style.height = 120 + 'px';
        }
        else{
            $container.style.height = 104 + 'px';
        }

        $container.classList.remove(navSideOpenClass)
        $btn.innerHTML = 'Смотреть всё +'
    }
}

