let $newCardBtn = document.querySelector('.specialist-order-info__prices-item--new');

let bankCardData = {
    'pan': null,
    'cardHolder': null,
    'expDate': null,
    'cvv': null
}

if ($newCardBtn) {
    // $newCardBtn.addEventListener('click', e => {
    //     modals.open('new-bank-card')
    // })
}

let $bankCardContainer = document.querySelector('.bank-card-new-data')


if ($bankCardContainer) {

    let $containerPAN = $bankCardContainer.querySelector('.bank-card--PAN')
    let $containerCardHolder = $bankCardContainer.querySelector('.bank-card--CardHolder')
    let $containerExpDateY = $bankCardContainer.querySelector('.bank-card--ExpDate-y')
    let $containerExpDateM = $bankCardContainer.querySelector('.bank-card--ExpDate-m')
    let $containerCVV = $bankCardContainer.querySelector('.bank-card--CVV')

    let $saveCardBtn = document.querySelector('.order-bank-card-save-btn')
    if ($saveCardBtn) {
        $saveCardBtn.addEventListener('click', e => {
            bankCardData.pan = $containerPAN.value;
            bankCardData.cardHolder = $containerCardHolder.value;
            bankCardData.expDate = $containerExpDateM.value + $containerExpDateY.value;
            bankCardData.cvv = $containerCVV.value;

            let customEvent = new CustomEvent('get-card-data', {'detail': {"bankCardData": bankCardData}})
            document.dispatchEvent(customEvent);
        })
    }
}
