const searchHints = Array.from(document.querySelectorAll('.search-hints'));
import axios from "axios";

const $categoryBtn = document.getElementById('headerCatalogBtn');
//console.log(searchHints);

searchHints.forEach(($searchHint) => {
    handlerSearchHints($searchHint);
});

function handlerSearchHints($wrapper) {
    const id = $wrapper.id;
    const $input = $wrapper.querySelector('.search-hints-input');
    const $inputButton = $wrapper.querySelector('.search-hints-button');
    const $wrapperContainer = $wrapper.querySelector('.search-hints__hints-wrapper')

    //шаблоны для вставки
    const $templateBig = $wrapper.querySelector('[data-search-hints-template-big]');
    const $template = $wrapper.querySelector('[data-search-hints-template]');

    //список списка сущностей для вывода подсказок
    const $wrapperLists = $wrapper.querySelectorAll('[data-search-hints-list]');

    const wrapperClass = 'search-hints';
    const activeClass = 'search-hints--active';
    const resultItemClass = 'search-hints__hints-results-item';
    const resultItemTitleClass = 'search-hints__hints-results-item-title';
    const resultItemHideClass = 'search-hints__hints-results-item--hide';


    let request = {
        search: '',
        types: []
    };
    let searchText = '';


    $wrapperLists.forEach($wrapperList => {
        request.types.push($wrapperList.dataset.searchHintsList)
    })

    init();

    function init() {
        addListeners();
        //setData(window.searchHintInitData);
    }

    function addListeners() {
        $input.addEventListener('input', () => {
            searchText = $input.value;

            if ($input.value.length >= 1) {

                request.search = $input.value;

                axios.post(window.searchHintUrl, request)
                    .then((response) => {
                        //console.log(response.data);
                        setData(response.data);
                    })
                    .catch((error) => {
                        //console.log(error);
                    });

                $wrapperContainer.style.display = 'block';
                $wrapper.classList.add(activeClass);
            } else {
                $wrapperContainer.style.display = 'none';
                //setData(window.searchHintInitData);
            }
        });


        document.addEventListener('click', (event) => {
            const $target = event.target;
            //console.log('клик по');

            // Если клик по поиску
            if ($target === $inputButton) {
                //console.log('поиску');
                ////console.log("SEARCH", searchText)

                if (searchText.length >= 1) {
                    const request = {
                        request: searchText
                    };

                    axios.post(window.searchAcceptUrl, request)
                        .then((response) => {
                            window.location.href = window.location.origin + '/products?filter[title]=' + searchText;
                        })
                        .catch((error) => {
                            //console.log(error);
                        });
                }
                return;
            }

            // Если клик по инпуту
            if ($target === $input) {
                if (searchText.length >= 1) {
                    //console.log('инпуту');
                    $wrapperContainer.style.display = 'block';
                    $wrapper.classList.add(activeClass);
                }
                return;
            }

            if ($target.classList.contains('search-hints-option')) {
                $wrapper.classList.remove(activeClass);
                return;
            }

            // Если клик непосредственно по кнопке открытия
            if ($target.dataset.searchHintsOpen && $target.dataset.searchHintsOpen === `${id}`) {
                //console.log('по кнопке открытия');
                //$wrapper.classList.add(activeClass);
                //$categoryBtn.classList.add("hidden");
                return;
            }

            // Если клик непосредственно по чему-то внутри кнопки открытия
            if ($target.closest(`[data-search-hints-open]`) && $target.closest(`[data-search-hints-open]`).dataset.searchHintsOpen === `${id}`) {
                //console.log('чему-то внутри кнопки открытия');
                //$wrapper.classList.add(activeClass);
                //$categoryBtn.classList.add("hidden");
                return;
            }

            // Если клик непосредственно по кнопке закрытия
            if ($target.attributes['data-search-hints-close'] && ($target.attributes['data-search-hints-close'] !== '' && $target.attributes['data-search-hints-close'] !== null)) {
                //console.log('кнопке закрытия');
                $wrapper.classList.remove(activeClass);
                $wrapperContainer.style.display = 'none';
                //$categoryBtn.classList.add("hidden");
                return;
            }

            // Если клик непосредственно по чему-то внутри кнопки закрытия
            if ($target.closest(`[data-search-hints-close]`)) {
                //console.log('чему-то внутри кнопки закрытия');
                $wrapper.classList.remove(activeClass);
                $wrapperContainer.style.display = 'none';
                //$categoryBtn.classList.remove("hidden");
                return;
            }

            // Если клик по врапперу или по его обертке
            if ($target === $wrapper || $target.closest(`.${wrapperClass}`) === $wrapper) {
                //console.log('врапперу или по его обертке');
                return;
            }
            //$categoryBtn.classList.remove("hidden");
            //console.log('ничему')
            $wrapperContainer.style.display = 'none';
            $wrapper.classList.remove(activeClass);


        });
    }

    function setData(searchLists) {

        let $currentWrapperLists = $wrapper.querySelectorAll('[data-search-hints-list]');
        $currentWrapperLists.forEach($currentWrapperList => {
            $currentWrapperList.innerHTML = '';
            $currentWrapperList.closest(`.${resultItemClass}`).classList.add(resultItemHideClass);
        });

        //console.log(searchLists);


        if (searchLists.length == 0) {
            //console.log('Ничего не найдено')
            let $currentWrapperList = $wrapper.querySelector('[data-search-hints-list="none"]');
            //console.log($currentWrapperList);
            if ($currentWrapperList) {
                $currentWrapperList.innerHTML = '';
                $currentWrapperList.closest(`.${resultItemClass}`).classList.remove(resultItemHideClass);

                const $clone = document.importNode($template.content, true);
                const $link = $clone.querySelector('.search-hints__hints-results-row-link');
                $link.classList.add('search-hints-option');
                //$link.classList.add('search-hints-option--hoverless');
                $link.innerHTML = '<span style="font-size: 16px; font-weight: 700; ">Ничего не найдено</span>  <div class="search-hints__hints-results-row-link-sub">Пожалуйста, проверьте ваш запрос</div>'

                $currentWrapperList.appendChild($clone);
            }
            return;
        }

        request.types.forEach(searchType => {
            //console.log(searchType);
            let $currentWrapperList = $wrapper.querySelector('[data-search-hints-list="' + searchType + '"]');
            if (searchLists.hasOwnProperty(searchType)) {
                $currentWrapperList.innerHTML = '';
                $currentWrapperList.closest(`.${resultItemClass}`).classList.remove(resultItemHideClass);

                searchLists[searchType].forEach(searchList => {
                    const $clone = document.importNode($template.content, true);
                    const $link = $clone.querySelector('.search-hints__hints-results-row-link');

                    if ($wrapper.dataset.hintsType == 'href') {
                        $link.href = $currentWrapperList.dataset.href + searchList['href_pref'] + searchList['href_key'];
                    }

                    const regex = request.search;
                    const invertedRegex = invertCase(regex);
                    let newStr = searchList.name.replace(regex, '<span style="background:#EBF5FF">' + regex + '</span>');
                    newStr = newStr.replace(invertedRegex, '<span style="background:#EBF5FF">' + invertedRegex + '</span>');

                    $link.setAttribute('data-head', searchType)
                    $link.setAttribute('data-value', searchList.id)
                    $link.classList.add('search-hints-option');
                    $link.innerHTML = '<p>' + newStr + '</p>'; // searchList.name;

                    if (searchList.category) {

                        $link.innerHTML = '<p>' + newStr + ' <div class="search-hints__hints-results-row-link-sub">' + searchList.category + '</div>' + '</p>'
                    }

                    $currentWrapperList.appendChild($clone);
                })
            }
        })


        let $sarchHintsOptions = $wrapper.querySelectorAll('.search-hints-option');
        $sarchHintsOptions.forEach($sarchHintsOption => {
            $sarchHintsOption.addEventListener('click', e => {
                let customEvent = new CustomEvent('filterSearchPicked', {
                    'detail': {
                        "head": $sarchHintsOption.dataset.head,
                        "value": $sarchHintsOption.dataset.value,
                        "name": $sarchHintsOption.innerHTML
                    }
                })
                document.dispatchEvent(customEvent);
            })

        })

    }
}
function invertCase(str) {
    const firstChar = str.charAt(0);
    const restOfStr = str.slice(1);
    if (firstChar === firstChar.toUpperCase()) {
        return firstChar.toLowerCase() + restOfStr;
    } else if (firstChar === firstChar.toLowerCase()) {
        return firstChar.toUpperCase() + restOfStr;
    }
    return str; // возвращаем исходную строку, если первый символ не является буквой
}