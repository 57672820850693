let $navbar = document.getElementsByClassName('header__navbar')[0];
let $topPanel = document.getElementsByClassName('header__navbar-top-panel')[0];
let $navbarContact = document.getElementsByClassName('header__navbar-top-panel-contact')[0];
let $navbarInnerNav = document.getElementsByClassName('header__navbar-inner-panel')[0];
let $navbarToggle = document.getElementsByClassName('header__navbar-toggle')[0];
let $navbarBtn = document.getElementsByClassName('header__navbar-btn')[0];
let $mBtn = document.getElementsByClassName('header__button--m')[0];
let $searchWrapBtn = document.getElementsByClassName('header__navbar-search')[0];
let $searchPanel = document.getElementsByClassName('header__navbar-search-panel')[0];

let $navbarClose = document.getElementsByClassName('header__navbar-m-nav-close')[0];

let navbarScrollClass = 'header__navbar--slide'
let navbarContactActiveClass = 'header__navbar-top-panel-contact--active'
let navbarInnerActiveClass = 'header__navbar-inner-panel--active'
let navbarTopActiveClass = 'header__navbar-top-panel--active'
let navbarSearchPanelActiveClass = 'header__navbar-search-panel--active'
if($navbar)
{
    function checkNavbarBtnClick(){
        if(!$navbarInnerNav.classList.contains(navbarInnerActiveClass)){
            $navbarInnerNav.classList.add(navbarInnerActiveClass)
        }else {
            $navbarInnerNav.classList.remove(navbarInnerActiveClass)
        }
    }

    function checkNavbarToggleClick(){
        if(!$navbarContact.classList.contains(navbarContactActiveClass)){
            $navbarContact.classList.add(navbarContactActiveClass)
            $topPanel.classList.add(navbarTopActiveClass);
            $navbarToggle.style.display ='none';
        }else {
            $navbarContact.classList.remove(navbarContactActiveClass)
            $topPanel.classList.remove(navbarTopActiveClass)
            $navbarToggle.style.display ='flex';

        }
    }

    function checkScrollClass() {
        if (window.scrollY > 0) {
            $navbar.classList.add(navbarScrollClass);
        } else {
            $navbar.classList.remove(navbarScrollClass);
        }
    }

    function checkSearchToggle(){
        if(!$searchPanel.classList.contains(navbarSearchPanelActiveClass)){
            $searchPanel.classList.add(navbarSearchPanelActiveClass)
            let $searchPanelInput = $searchPanel.querySelector('input');
            $searchPanelInput.focus();
        }else {
            $searchPanel.classList.remove(navbarSearchPanelActiveClass)
        }
    }

    if ($navbarBtn)
        $navbarBtn.addEventListener('click', ()=>{
            checkNavbarBtnClick()
        })

    if ($navbarClose){
        $navbarClose.addEventListener('click', ()=>{
            checkNavbarToggleClick();
        })
    }

    if ($navbarToggle)
        $navbarToggle.addEventListener('click', ()=>{
            checkNavbarToggleClick();
        })


    if($searchWrapBtn){
        $searchWrapBtn.addEventListener('click', ()=>{
            checkSearchToggle();
        })
    }

    window.addEventListener('scroll', () => {
        checkScrollClass();
    })

    checkScrollClass();
}




