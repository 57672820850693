let $playerBtns = document.querySelectorAll('.card-player-btn');
let $playerContainer = document.querySelector('.card-player')

if ($playerContainer) {
    $playerBtns.forEach($playerBtn => {
        $playerBtn.addEventListener('click', e => {
            let url = $playerBtn.dataset.url;
            let path = $playerBtn.dataset.path
            renderPlayer($playerContainer, url, path);
        })

    });

    document.addEventListener('player-close', e => {
        $playerContainer.innerHTML = '';
    })
}

function renderPlayer($container, url, path) {
    let videoContent = '';
    if (path){
         videoContent =
            '<video width="940" height="670" controls autoplay style="margin: 0 auto">\n' +
            '                <source src="' + path + url + '" type="video/mp4">\n' +
            '                Your browser does not support the video tag.\n' +
            '            </video>' +
            '<div id="modal-video-close"  class="modal__header-close ">\n' +
            '                    <svg class="modal__header-close-icon modal__header-close-icon--w">\n' +
            '                        <use xlink:href="https://sense-life.ru/public/icons/icons-sprite.svg#close"></use>\n' +
            '                    </svg>\n' +
            '                </div> ';
    }
    else{
         videoContent =
            '<video width="940" height="670" controls autoplay style="margin: 0 auto">\n' +
            '                <source src="/video/' + url + '" type="video/mp4">\n' +
            '                Your browser does not support the video tag.\n' +
            '            </video>' +
            '<div id="modal-video-close"  class="modal__header-close ">\n' +
            '                    <svg class="modal__header-close-icon modal__header-close-icon--w">\n' +
            '                        <use xlink:href="https://sense-life.ru/public/icons/icons-sprite.svg#close"></use>\n' +
            '                    </svg>\n' +
            '                </div> ';
    }


    $container.innerHTML = videoContent;
    let $closeBtn = document.querySelector('#modal-video-close');
    $closeBtn.addEventListener('click', e=>{
        modals.close('modal-video');
        $container.innerHTML = '';
    })


}
