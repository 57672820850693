import {getCookie} from "../cookie/cookie";

let $specialistsContainer = document.querySelector('.crm-last-dates');

let $startOrderBtn = document.querySelector('.start-order-btn');

if ($specialistsContainer != null) {
    getLastDates();

    document.addEventListener('timezone-changed', e=>{
        getLastDates();
    })
}

function getLastDates() {
    //TODO: CRM new
    //return false

    let timezone = getCookie('timezone');
    let timezoneParam = ''

    if (timezone)
    {
        timezoneParam = '?timezone=' + timezone
    }

    let url = '/specialists/sync-last-date' + timezoneParam
    axios.get(url)
        .then((response) => {
            //console.log('RESP')
            //console.log(response.data);
            setDates(response.data)

        })
        .catch((error) => {
            console.log(error)
            // setTimeout(function () {
            //     getLastDates()
            // }, 5000)
        });
}





function setDates(data) {
    //console.log('SET DATES _____')
    //console.log(data)
    let $specialistsContainer = document.querySelectorAll('.crm-last-dates .specialist-new__container-card')

    $specialistsContainer.forEach($specialistContainer=>{
        let specialistCRM = $specialistContainer.dataset.crm;
        let $noDateContainer = $specialistContainer.querySelector('.specialist-new__card-nothing');
        let $dateContainer = $specialistContainer.querySelector('.specialist-new__container-card-price-box');
        let $orderBtns = $specialistContainer.querySelectorAll('.specialist-order-btn');

        try{
           // console.log(data[specialistCRM][0]);
            if (data[specialistCRM][0])
            {
                let $specialistDateBlock = $specialistContainer.querySelectorAll('.specialist-new__container-card-price-box-text')
                $specialistDateBlock.forEach($b => {
                    $b.innerText = data[specialistCRM][0]['formated'];
                })
            }
            else {
                $dateContainer.style.display = 'none';
                $noDateContainer.style.display = 'flex';
                $orderBtns.forEach($orderBtn=>{
                    $orderBtn.style.display = 'none'
                })
            }
        }catch (ex){
            $dateContainer.style.display = 'none';
            $noDateContainer.style.display = 'flex';
            $orderBtns.forEach($orderBtn=>{
                $orderBtn.style.display = 'none'
            })
        }


    })
}

let $specialistDatesContainer = document.querySelector('.crm-dates-list');

if ($specialistDatesContainer) {


    document.addEventListener('timezone-changed', e=>{

        console.log('free-date')

        getSpecialistAvalibleDate($specialistDatesContainer.dataset.specialist)
    })


    let $specialistDatesContainerItems = $specialistDatesContainer.querySelectorAll('[data-date]')
    console.log('$specialistDatesContainer');
    console.log($specialistDatesContainerItems);
    $specialistDatesContainerItems.forEach($dateItem => {
        $dateItem.addEventListener('click', e => {
            getSpecialistAvalibleDate($specialistDatesContainer.dataset.specialist)
        })
    })
    getSpecialistAvalibleDate($specialistDatesContainer.dataset.specialist)

    document.addEventListener('specialist-order-custom-date', e => {
        console.log('CUSOM DATE')
        setCustomDate();
        modals.close('modal-date');
    })
}

function setCustomDate() {
    let $customHead = document.querySelector('.more-date-btn');
    let $customBody = document.querySelector('.crm-dates-list-container-custom')

    let $customBodyContainer = $customBody.querySelector('.specialist-main__card-info-days-content-items');
    let $calendar = document.querySelector('.calendar')
    let $setDate = document.querySelector('.crm-dates-list-container-custom-date')
    let $containerCurrent = document.querySelector('.container.current')


    let weekArr = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    let monthArr = ['янв', 'фев', 'март', 'апр', 'май', 'июнь',
        'июль', 'авг', 'сент', 'окт', 'нояб', 'дек'];
    let monthArrFull = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

    let time = $calendar.dataset.time;
    let date = $calendar.dataset.day;

    let dt = new Date(date);
    let week = dt.getDay()
    let day = dt.getDate()
    let month = dt.getMonth()

    let calendarMonth = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)

    let formateDate = dt.getFullYear() + '-' + calendarMonth + '-' + dt.getDate();  //=2023-01-21
    if ($startOrderBtn) {
        $startOrderBtn.dataset.date = date;
        $startOrderBtn.dataset.time = time;
    }


    //$customHead.innerHTML = weekArr[week - 1] + ', ' + day + ' ' +  monthArr[month];
    $setDate.innerHTML = 'Выбрано: <span>' + day + ' ' + monthArrFull[month] + ', ' + time + '</span>';

    let timesArr = $containerCurrent.dataset.times.split(',');
    //refreshDateTime($customBodyContainer, timesArr)
    renderTime($customBodyContainer, timesArr, day, time, month, formateDate)
    return
}

function renderTime($timeContainer, times, date, curTime, month, dt) {

    console.log('RENDER TIME')
    //$timeContainer = document.querySelector('.calendar__times');
    let $innerTimeContent = ''


    times.forEach(time => {
        console.log(time)
        if (time != '') {
            if ((time == curTime))
                $innerTimeContent += '<div><div data-month="' + month + '" data-date="' + dt + '" data-time="' + time + '" class="specialist-main__card-info-days-content-item specialist-main__card-info-days-content-item--active">' + time + '</div></div>'
            else {
                $innerTimeContent += '<div><div data-month="' + month + '" data-date="' + dt + '" data-time="' + time + '" class="specialist-main__card-info-days-content-item">' + time + '</div></div>'
            }
        }

    })
    $timeContainer.innerHTML = $innerTimeContent;

    let $allBtns = $timeContainer.querySelectorAll('.specialist-main__card-info-days-content-item')
    $allBtns.forEach($btn => {
        $btn.addEventListener('click', e => {
            activateOrderTimeBtn($btn)
            refreshCalendarDate($btn)


            let $setDate = document.querySelector('.crm-dates-list-container-custom-date')
            let monthArrFull = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
                'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

            let time = $btn.dataset.time;
            let month = $btn.dataset.month;
            let date = $btn.dataset.date;

            let dt = new Date(date);
            let day = dt.getDate()

            $setDate.innerHTML = 'Выбрано: <span>' + day + ' ' + monthArrFull[month] + ', ' + time + '</span>';

            let event = new CustomEvent("order-date-change");
            document.dispatchEvent(event);

            //let event = new CustomEvent("order-date-change");
            //document.dispatchEvent(event);
        })
    })
}


function getSpecialistAvalibleDate(spec_id) {
    let timezone = getCookie('timezone');
    let timezoneParam = ''

    if (timezone)
    {
        timezoneParam = '?timezone=' + timezone
    }

    let url = '/specialist/' + spec_id + '/free-dates' + timezoneParam
    let $tabs = document.querySelector('.specialist-order-times');

    axios.get(url)
        .then((response) => {
            console.log(response)
            if (response.data == null) {
                renderDatesTab($tabs, null)
            } else {
                renderDatesTab($tabs, response.data)
            }
            setTimeout(function () {
                $tabs.classList.remove('preload');
            }, 500)

        })
        .catch((error) => {
            console.log(error);
            setTimeout(function () {
                getSpecialistAvalibleDate(spec_id)
            }, 1000)


        });
}

function renderDatesTab($tabs, dates) {
    let $containers = $tabs.querySelectorAll('.crm-dates-list .crm-dates-list-head')
    console.log('renderDatesTab')
    console.log(dates)

    if (!dates || dates.length <= 0) {
        let $emptyDateContainer = document.querySelector('.specialist-main__card-nothing');
        $emptyDateContainer.style.display = 'flex';

        let $hideBlocks = document.querySelectorAll('.nothing-hide');


        $hideBlocks.forEach(($block, i) => {
            $block.style.display = 'none';
        });

        //console.log("DISPATCH")
        let event = new CustomEvent("order-slider-refresh");
        document.dispatchEvent(event);
        setTimeout(function () {
            let $containerBody = $tabs.querySelector('.crm-dates-list-container[data-tab_to="2"]')
            $containerBody.classList.remove('tabs-body-item--active')
        }, 300)
        return
    }

    let datesArr = Object.values(dates);
    //$key = $keys[1];
    //$value = $inArray[$key];

    $containers.forEach(($container, i) => {
        //console.log("START " + i)
        //console.log($container)
        //console.log(datesArr[i]);


        //TODO: fix shit
        if (!datesArr[i]) {
            $container.style = 'display: none;'
            return
        }

        let currentDateKey = Object.keys(dates)[i];
        let currentDateTimes = dates[currentDateKey]['times'];
        let currentDateDate = dates[currentDateKey]['date'];


        $container.dataset.times = currentDateTimes;
        $container.dataset.date = currentDateDate;
        $container.innerText = currentDateKey;


        let $containerBody = $tabs.querySelector('.crm-dates-list-container[data-tab_to="' + $container.dataset.tab + '"]')

        let $containerBodySlider = $containerBody.querySelector('.order-days-slider')

        let htmlContent = ''
        currentDateTimes.forEach((time, i) => {
            htmlContent += '<div><div data-date="' + currentDateDate + '" data-time="' + time + '" class="specialist-main__card-info-days-content-item">' + time + '</div></div>'
        })
        $containerBodySlider.innerHTML = htmlContent;


        $container.addEventListener('click', e => {
            let event = new CustomEvent("order-slider-regenerate");
            document.dispatchEvent(event);
            refreshCalendarDate($container)
        })


    })


    //console.log("DISPATCH")
    let event = new CustomEvent("order-slider-refresh");
    document.dispatchEvent(event);
    setTimeout(function () {
        let $containerBody = $tabs.querySelector('.crm-dates-list-container[data-tab_to="2"]')
        $containerBody.classList.remove('tabs-body-item--active')
    }, 300)

    let $allBtns = document.querySelectorAll('.specialist-main__card-info-days-content-item')
    $allBtns.forEach($btn => {
        $btn.addEventListener('click', e => {
            activateOrderTimeBtn($btn)
            refreshCalendarDate($btn)
            let event = new CustomEvent("order-date-change");
            document.dispatchEvent(event);
        })
    })
}

function refreshCalendarDate($btn) {
    let $calendar = document.querySelector('.calendar')

    if ($calendar) {
        $calendar.dataset.time = $btn.dataset.time ?? null;
        $calendar.dataset.day = $btn.dataset.date;
    }

}

function activateOrderTimeBtn($btn) {
    let orderBtn = document.querySelector('.start-order-btn')

    if (orderBtn) {
        orderBtn.dataset.time = $btn.dataset.time;
        orderBtn.dataset.date = $btn.dataset.date;
    }


    refreshActiveBtn($btn);

}

function refreshActiveBtn($curBtn = null) {
    let $allBtns = document.querySelectorAll('.specialist-main__card-info-days-content-item')
    $allBtns.forEach($btn => {
        $btn.classList.remove('specialist-main__card-info-days-content-item--active')
    })
    if ($curBtn)
        $curBtn.classList.add('specialist-main__card-info-days-content-item--active')
}

function refreshDateTime($container, timesArr) {
    if (time == null) {
        $container.innerHTML = 'Выберите свободную дату';
        return
    }

    refreshActiveBtn();

    timesArr.forEach()

    let htmlContent = '<div><div data-date="' + date + '" data-time="' + time + '"  class="specialist-main__card-info-days-content-item specialist-main__card-info-days-content-item--active ">' + time + '</div></div>'
    $container.innerHTML = htmlContent;
    modals.close('modal-date');
    return
}

function refreshDates($container, dates) {

    //console.log(dates)
    if (dates == null) {
        $container.innerHTML = 'Нет свободного времени';
        return
    }

    let itemsHtml = ''
    dates.forEach(date => {
        //console.log(date)
        itemsHtml += `<div class="specialist-main__card-info-days-content-item">
           ${date}</div>
        `
    })

    //console.log(itemsHtml)
    $container.innerHTML = itemsHtml;
}


