import {getCookie} from "../cookie/cookie";
import {validationCheck} from "../input/validation";
import {phoneVerification} from "../phone-verification/phone-verification";

let $appointmentModal = null//document.querySelector('#appointment')
//modals.open('thank-you-modal');
if ($appointmentModal) {
    let $appointmentModalInputs = $appointmentModal.querySelectorAll('.input__form');
    let $appointmentModalRequiredInputs = $appointmentModal.querySelectorAll('.input__form--require');
    let $appointmentModalSendBtn = $appointmentModal.querySelector('.btn-send')
    let $appointmentCheckbox = $appointmentModal.querySelector('.checkbox__input');

    let inputErrorClass = 'input--error';

    let reqData = {
        name: null,
        surname: null,
        phone: null,
        spec: null,
    };
    let hasEmptyInputs = false;
    $appointmentModalSendBtn.addEventListener('click', event => {
        hasEmptyInputs = false;

        $appointmentModalRequiredInputs.forEach($input => {
            if ($input.value == '') {
                $input.parentElement.classList.add(inputErrorClass)
                hasEmptyInputs = true;
            }
        })

        if (!$appointmentCheckbox.checked) {
            hasEmptyInputs = true;
        }

        if (hasEmptyInputs == true)
            return;

        $appointmentModalInputs.forEach($input => {
            reqData[$input.name] = $input.value
        })


        if (validationCheck($appointmentModal) == false) {
            return 0;
        }

        $appointmentModalSendBtn.classList.add('btn-load');
        $appointmentModalSendBtn.value = "";


        axios({
            method: 'POST',
            url: `${window.location.origin}/feedback/send`,
            data: reqData,
        }).then((response) => {
            let $crmDesc = 'Быстрая запись:\n имя: ' + reqData['name'] + '\n телефон: ' + reqData['phone']
            if (reqData['spec'] != "undefined") {
                $crmDesc += '\n специалист: ' + reqData.spec;
            }

            let $crmData = {
                'name': '1. Быстрая запись на сеанс',
                'desc': $crmDesc
            }

            if ($appointmentModal.dataset.page == 'landing') {
                $crmData = {
                    'name': 'Быстрая запись на сеанс (лэндинг)',
                    'desc': $crmDesc
                }
                ym(88810526, 'reachGoal', 'landing-fast-order')
            } else {
                ym(88810526, 'reachGoal', 'specialist-fast-order')
            }


            sendcbToCRM($crmData, false);
            $appointmentModalSendBtn.value = "Записаться"
            $appointmentModalSendBtn.classList.remove('btn-load');

            modals.close()
            modals.open('thank-you-modal')

        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
            $appointmentModalSendBtn.value = "Записаться"
            $appointmentModalSendBtn.classList.remove('btn-load');
        });
    })

}


let $modalCallbackPhone = document.querySelector('.cb-sender');
if ($modalCallbackPhone) {
    let page = $modalCallbackPhone.dataset.page;

    let $cbBtn = $modalCallbackPhone.querySelector('.cb-btn');
    let $cbName = $modalCallbackPhone.querySelector('.cb-name');
    let $cbPhone = $modalCallbackPhone.querySelector('.cb-phone');
    let $cbText = $modalCallbackPhone.querySelector('.cb-text');

    let $textVal = ''
    if ($cbText) {
        $textVal = $cbText.value;
    }

    let $cbParams = $modalCallbackPhone.querySelectorAll('.visit-radio');


    $cbBtn.addEventListener('click', e => {
        console.log('CLICK _CB')
        let isMessenger = false;

        let subject = "2. Свяжитесь со мной";

        let cbParams = [];
        $cbParams.forEach($cbParam => {
            if ($cbParam.checked) {
                if ($cbParam.dataset.type == 'messenger') {
                    isMessenger = true
                    subject = "2. Свяжитесь со мной (МЕССЕНДЖЕР)";
                }
                cbParams.push($cbParam.dataset.value);
            }
        })


        let reqData = {
            phone: $cbPhone.value,
            name: $cbName.value,
            cbParams: cbParams,
            text: $textVal,
            subject: subject
        };


        if (validationCheck($modalCallbackPhone) == false) {
            return 0;
        }

        $cbBtn.classList.add('btn-load')
        $cbBtn.innerHTML = '';

        axios({
            method: 'POST',
            url: `${window.location.origin}/phonecb/send`,
            data: reqData,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            let $crmDesc = 'Телефон: ' + reqData.phone + '\n ' +
                'Имя: ' + reqData.name + '\n ' +
                'Коментарий: ' + reqData.text + '\n ';


            reqData.cbParams.forEach(cbParam => {
                $crmDesc += cbParam;
            })

            let $crmData = {
                'name': '2. Свяжитесь со мной',
                'desc': $crmDesc
            }

            if (isMessenger) {
                ym(88810526, 'reachGoal', 'messenger-callback')
                $crmData = {
                    'name': '2. Свяжитесь со мной (МЕССЕНДЖЕР)',
                    'desc': $crmDesc
                }
            } else {
                if (page == 'app-page-callback') {
                    ym(88810526, 'reachGoal', 'callback-main')
                } else if (page == 'home-page-callback') {
                    ym(88810526, 'reachGoal', 'home-page-callback')
                } else if (page == 'blog-page-callback') {
                    ym(88810526, 'reachGoal', 'blog-page-callback')
                } else if (page == 'list-page-callback') {

                     $crmData = {
                        'name': '4. Помощь в подборе листинг',
                        'desc': $crmDesc
                    }

                    ym(88810526, 'reachGoal', 'list-page-callback')
                }
            }

            sendcbToCRM($crmData, false);
            modals.close()
            modals.open('thank-you-modal')
        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });
    })

}

let $modalLandingCallbackPhone = document.querySelector('.cb-landing-sender');
if ($modalLandingCallbackPhone) {
    let page = $modalLandingCallbackPhone.dataset.page;

    let $cbBtn = $modalLandingCallbackPhone.querySelector('.cb-btn');
    let $cbName = $modalLandingCallbackPhone.querySelector('.cb-name');
    let $cbPhone = $modalLandingCallbackPhone.querySelector('.cb-phone');
    let $cbText = $modalLandingCallbackPhone.querySelector('.cb-text');

    let $textVal = ''
    if ($cbText) {
        $textVal = $cbText.value;
    }

    $cbBtn.addEventListener('click', e => {
        console.log('CLICK _CB')
        let isMessenger = false;

        let reqData = {
            phone: $cbPhone.value,
            name: $cbName.value,
            text: $textVal,
        };


        if (validationCheck($modalLandingCallbackPhone) == false) {
            return 0;
        }

        $cbBtn.classList.add('btn-load')
        $cbBtn.innerHTML = '';

        axios({
            method: 'POST',
            url: `${window.location.origin}/landingcb/send`,
            data: reqData,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            let $crmDesc = 'Телефон: ' + reqData.phone + '\n ' +
                'Имя: ' + reqData.name + '\n ' +
                'Коментарий: ' + reqData.text + '\n ';


            let $crmData = {
                'name': 'Свяжитесь со мной (лэндинг)',
                'desc': $crmDesc
            }

            ym(88810526, 'reachGoal', 'landing-callback')


            sendcbToCRM($crmData, false);
            modals.close()
            modals.open('thank-you-modal')

        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });
    })

}


let $modalCallbackHelp = document.querySelector('.cb-help-sender');
if ($modalCallbackHelp) {
    let page = $modalCallbackHelp.dataset.page;

    let $cbBtn = $modalCallbackHelp.querySelector('.cb-btn');
    let $cbName = $modalCallbackHelp.querySelector('.cb-name');
    let $cbPhone = $modalCallbackHelp.querySelector('.cb-phone');
    let $cbText = $modalCallbackHelp.querySelector('.cb-text');
    let $cbCheckbox = $modalCallbackHelp.querySelector('.checkbox__input');


    let $textVal = ''
    if ($cbText) {
        $textVal = $cbText.value;
    }

    let $cbParams = $modalCallbackHelp.querySelectorAll('.visit-radio');


    $cbBtn.addEventListener('click', e => {
        console.log('CLICK _CB')

        if (!$cbCheckbox.checked) {
            return 0;
        }

        let cbParams = [];
        $cbParams.forEach($cbParam => {
            if ($cbParam.checked) {
                cbParams.push($cbParam.dataset.value);
            }
        })


        let reqData = {
            phone: $cbPhone.value,
            name: $cbName.value,
            cbParams: cbParams,
            text: $textVal,
        };


        if (validationCheck($modalCallbackHelp) == false) {
            return 0;
        }

        $cbBtn.classList.add('btn-load')
        $cbBtn.innerHTML = '';

        axios({
            method: 'POST',
            url: `${window.location.origin}/phonehelp/send`,
            data: reqData,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            let $crmDesc = 'Телефон: ' + reqData.phone + '\n ' +
                'Имя: ' + reqData.name + '\n '


            reqData.cbParams.forEach(cbParam => {
                $crmDesc += cbParam;
            })

            let $crmData = {
                'name': '4 Помощь в подборе (листинг)',
                'desc': $crmDesc
            }
            if (page == 'app-page-callback') {
                ym(88810526, 'reachGoal', 'callback-main')
            } else if (page == 'home-page-callback') {
                ym(88810526, 'reachGoal', 'home-page-callback')
            } else if (page == 'blog-page-callback') {
                ym(88810526, 'reachGoal', 'blog-page-callback')
            } else if (page == 'list-page-callback') {
                ym(88810526, 'reachGoal', 'list-page-callback')
            }

            sendcbToCRM($crmData, false);
            modals.close()
            modals.open('thank-you-modal')

        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });
    })

}


let $modalFedback = document.querySelector('.cb-convenient')


if ($modalFedback) {
    let $modalFedbackBtn = $modalFedback.querySelector('.btn-blue');
    let $modalFedbackEmail = $modalFedback.querySelector('.convenient-email');
    let $modalFedbackPhone = $modalFedback.querySelector('.convenient-phone');


    $modalFedbackBtn.addEventListener('click', e => {
        let redirect = '/thank-you-s'
        let fromPage = $modalFedback.dataset.page;

        let reqData = {
            phone: $modalFedbackPhone.value,
            email: $modalFedbackEmail.value,
            specialist: $modalFedback.dataset.specialist
        };

        if (validationCheck($modalFedback) == false) {
            return 0;
        }


        axios({
            method: 'POST',
            url: `${window.location.origin}/convenient/send`,
            data: reqData,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            let $crmDesc = 'Телефон: ' + reqData.phone + '\n' +
                'Почта: ' + reqData.email + '\n' +
                'Специалист: ' + reqData.specialist + '\n';

            let $crmData = {
                'name': '3. Не нашли удобное время (есть слоты)',
                'desc': $crmDesc
            }


            if (fromPage == 'specialist-list') {
                console.log('specialist-list')
                ym(88810526, 'reachGoal', 'convenient-time-list')
            } else if (fromPage == 'specialist-order') {
                console.log('specialist-order')
                ym(88810526, 'reachGoal', 'convenient-time-order')
            } else if (fromPage == 'specialist-page') {
                console.log('specialist-page')
                ym(88810526, 'reachGoal', 'convenient-time-page')
            } else if (fromPage == 'specialist-page-nothing') {
                $crmData['name'] = '3. Не нашли удобное время (нет слотов)';

                console.log('specialist-page-nothing')
                ym(88810526, 'reachGoal', 'specialist-page-nothing')
            }

            sendcbToCRM($crmData, false);
            modals.close()
            modals.open('thank-you-modal')
            //modals.close('appointment');
            // modals.open('appointment-success')
        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });
    })

}


let $specialistListNothingList = document.querySelectorAll('.specialist-new__card-nothing-btn')
if ($specialistListNothingList.length > 0) {
    let $container = document.querySelector('.cb-convenient')
    let $containerHidden = $container.querySelector('input[name="specialist_fio"]');

    $specialistListNothingList.forEach($specialistListNothing => {
        $specialistListNothing.addEventListener('click', e => {
            $container.dataset.specialist = $specialistListNothing.dataset.specialist;
            $containerHidden.value = $specialistListNothing.dataset.specialist;

        })
    })

}


let $changeModalBtns = document.querySelectorAll('.change-modal-page')

$changeModalBtns.forEach($changeModalBtn => {
    $changeModalBtn.addEventListener('click', e => {
        let modalId = $changeModalBtn.dataset.modalOpen;
        let modalChange = $changeModalBtn.dataset.modalpage;

        console.log(modalId, modalChange)

        let modal = document.querySelector('#' + modalId);
        if (modal) {

            modal.dataset.page = modalChange;
        }
    })
})


//OLD
/////////////////////////////
//NEW

let $SendFormContainers = document.querySelectorAll('.sform-container')
let ymId = 88810526;


$SendFormContainers.forEach($SendFormContainer => {

    SendForm($SendFormContainer);
})

function sendLog(data) {
    fetch('/log', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(data => {
        console.log(data)
    }).catch((error) => {
        console.error('Error:', error);
    });
}

function SendForm($form) {
    console.log('generateForm: ' + $form)

    let $formInputs = $form.querySelectorAll('.sform-input')
    let $formSenders = $form.querySelectorAll('.sform-sender')

    let ymKey = $form.dataset.ymKey;
    let sformTitle = $form.dataset.sformTitle;

    let isPhoneVerify = $form.dataset.phoneVerify;
    let phoneTools = null;

    let reqData = {
        title: sformTitle,
        data: {}
    };

    let crmData = {
        'name': sformTitle,
        'desc': '',
    }

    if (isPhoneVerify) {
        const $phoneVerificationModal = document.querySelector('#phone-verification');
        const $phone = $form.querySelector('.sform-phone-validator')


        $phone.addEventListener('change', e => {
            console.log('PHONE CHANGE');
            console.log($phone.value)
            sendLog({event: `${sformTitle} --- телефон --- до верификации`, phone: $phone.value});
        })


        phoneTools = phoneVerification(
            {
                $wrapper: $phoneVerificationModal,
                $startPhone: $phone,
                callback: (success) => {
                    if (success) {
                        console.log("Проверка прошла успешно!");
                        checkInputData()
                        send(reqData, ymKey)
                    }
                }
            });
    }


    $formSenders.forEach($formSender => {
        $formSender.addEventListener('click', e => {
            if (checkInputData()) {

                // if (isPhoneVerify)
                // {
                //     sendLog({event: `${sformTitle} --- телефон --- после верификации`});
                //     phoneTools.openModal();
                // }
                // else {
                send(reqData, ymKey)
                //  }

            }
        })
    })


    function send(reqData, ymKey) {
        console.log(reqData);
        axios({
            method: 'POST',
            url: `${window.location.origin}/sendform/send`,
            data: reqData,
        }).then((response) => {

            ym(ymId, 'reachGoal', ymKey)
            sendcbToCRM(crmData, false);

            modals.close()
            modals.open('thank-you-modal')

        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });
    }

    function fillCrmData() {
        $formInputs.forEach($formInput => {
            let crmDesc = '';

            for (const [key, value] of Object.entries(reqData.data)) {
                crmDesc += key + ': ' + value + '\n'
            }

            crmData.desc = crmDesc
        })

        return true;
    }

    function fillData() {
        console.log('fill data')
        console.log($formInputs)

        $formInputs.forEach($formInput => {
            let inputKey = $formInput.dataset.key
            let inputValue = $formInput.value

            console.log(inputKey, inputValue);

            reqData.data[inputKey] = inputValue;
        })

        return true;
    }

    function checkInputData() {
        if (validationCheck($form) == false) {
            return false;
        }

        if (fillData() == false) {
            return false;
        }

        if (fillCrmData() == false) {
            return false;
        }

        return true
    }
}


export function sendcbToCRM($data, callbackRedirect = null) {

    let redirect = '/thank-you-s'

    console.log('sendcbToCRM_____________')

    $data['cookie'] = {
        'utmSource': getCookie('utm_source'),
        'utmMedium': getCookie('utm_medium'),
        'utmCampaign': getCookie('utm_campaign'),
        'utmTerm': getCookie('utm_content'),
        'utmContent': getCookie('utm_term'),
    };

    $data['desc'] += '\n\n utmSource: ' + getCookie('utm_source') + '\n' +
        'utmMedium: ' + getCookie('utm_medium') + '\n' +
        'utmCampaign: ' + getCookie('utm_campaign') + '\n' +
        'utmTerm: ' + getCookie('utm_content') + '\n' +
        'utmContent: ' + getCookie('utm_term') + '\n';


    console.log($data);
        axios({
            method: 'POST',
            url: `${window.location.origin}/callback/crm-send`,
            data: $data,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            if (callbackRedirect != false) {
                if (callbackRedirect) {
                    window.location = callbackRedirect
                } else {
                    window.location = window.location.origin + redirect
                }
            }


        }).catch((err) => {
            console.log(err)
        });
}

