let $landingNav = document.querySelector('.landing-nav')
let $welcomeContainer = document.querySelector('.landing-welcome');
//let $landingContainer = document.querySelector('.landing');

//let $landingHeight = document.querySelector('.landing-nav__height');
let $landingHeight = document.querySelector('.landing-tasks');


let landingNAvFixedClass = 'landing-nav--fixed'
let landingAfterFixedClass = 'landing--after-fixed'
let landingHeightActiveClass = 'landing-tasks--fixed'


if ($landingNav && $welcomeContainer) {
    window.addEventListener('scroll', () => {
        let welcomeRect = $welcomeContainer.getBoundingClientRect()
        //console.log(welcomeRect.bottom )
        if (welcomeRect.bottom < 0){
            //console.log($landingHeight.classList)
            if (!$landingNav.classList.contains(landingNAvFixedClass)){

                $landingNav.classList.add(landingNAvFixedClass)
               // $landingContainer.classList.add(landingAfterFixedClass)
                $landingHeight.classList.add(landingHeightActiveClass)
            }
        }
        else {
            if ($landingNav.classList.contains(landingNAvFixedClass)){

                $landingNav.classList.remove(landingNAvFixedClass)
                //$landingContainer.classList.remove(landingAfterFixedClass)
                $landingHeight.classList.remove(landingHeightActiveClass)
            }
        }

    });
}

let $landingHeader = document.querySelector('.landing-header')
let landingHeaderFixedClass = 'landing-header--fixed'

if ($landingHeader) {

    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            console.log('AAAAAAAAAA2');
            $landingHeader.classList.add(landingHeaderFixedClass);
        } else {
            console.log('AAAAAAAAAA3');
            $landingHeader.classList.remove(landingHeaderFixedClass);
        }
    })

}

