import {changeTimezone} from "../cookie/timezone-parser";
import {getCookie} from "../cookie/cookie";

let modalDateOpenBtn = document.querySelector('.more-date-btn')
let $timezoneSelects = document.querySelectorAll('.timezone-select');
let $specialistVisitOptions = document.querySelector('.specialist-main__card-info-tags');

if (modalDateOpenBtn) {
    modalDateOpenBtn.addEventListener('click', e => {
        let event = new CustomEvent("showCalendar");
        document.dispatchEvent(event);
    })

}

if ($timezoneSelects.length > 0) {

    $timezoneSelects.forEach($timezoneSelect=>{

        let $curTimezone = $timezoneSelect.querySelector('.specialist-main__card-info-timezone-text--link')
        let activeClass = 'specialist-main__card-info-timezone-container--active'
        let $timezoneContainer = $timezoneSelect.querySelector('.specialist-main__card-info-timezone-container');

        let timezone = getCookie('timezone');
        if (timezone){
            let $curTimezoneItem = $timezoneContainer.querySelector('.specialist-main__card-info-timezone-container-item[data-timezone="' + timezone + '"]')
            $curTimezone.innerHTML = $curTimezoneItem.innerHTML;
        }

        let $timezoneContainerItems = $timezoneContainer.querySelectorAll('.specialist-main__card-info-timezone-container-item')
        $curTimezone.addEventListener('click', e => {
            switchWrap();
        })

        $timezoneContainerItems.forEach($item => {
            $item.addEventListener('click', e => {
                switchWrap()
                switchTimezone(e.currentTarget);
            })
        })

        function switchTimezone($curItem) {
            changeTimezone($curItem.dataset.timezone)
        }

        function switchWrap() {
            if ($timezoneContainer.classList.contains(activeClass)) {
                $timezoneContainer.classList.remove(activeClass)
            } else {
                $timezoneContainer.classList.add(activeClass)
            }
        }

        document.addEventListener('timezone-changed', e=>{
            let timezone = getCookie('timezone');

            if (timezone){
                let $curTimezoneItem = $timezoneContainer.querySelector('.specialist-main__card-info-timezone-container-item[data-timezone="' + timezone + '"]')
                $curTimezone.innerHTML = $curTimezoneItem.innerHTML;
            }
        })
    })
}


if ($specialistVisitOptions) {
    let $calendar = document.querySelector('.calendar');
    let $optionVisit = document.querySelector('.specialist-main__card-info-tags-item[data-value="visit"]');
    let $optionOnline = document.querySelector('.specialist-main__card-info-tags-item[data-value="online"]');
    let activeOptionClass = 'specialist-main__card-info-tags-item--active';

    let $priceValues = document.querySelectorAll('.specialist-main__card-info-prices-price')

    $calendar.dataset.visit = 'online'

    if (!$optionOnline){
        $calendar.dataset.visit = 'visit'
    }

    if ($optionVisit && $optionOnline) {
        $optionVisit.addEventListener('click', e => {
            changeVisitOption($optionOnline, $optionVisit)
            $calendar.dataset.visit = 'visit'
            $priceValues.forEach($price => {
                $price.innerHTML = $price.dataset.visit + '₽';
            })
        })
        $optionOnline.addEventListener('click', e => {
            changeVisitOption($optionVisit, $optionOnline)
            $calendar.dataset.visit = 'online'
            $priceValues.forEach($price => {
                console.log($price);
                $price.innerHTML = $price.dataset.online + '₽';
            })

        })
    }


    function changeVisitOption($from, $to) {
        $from.classList.remove(activeOptionClass)
        $to.classList.add(activeOptionClass)
    }


}
