import {formatPhoneToDB, formatPhoneToInterface} from "../../utilities/formatPhone/formatPhone";

let inputs = Array.from(document.querySelectorAll('.replace-phone-symbols'));

if (inputs.length > 0) {
    for (let $input of inputs) {
        replacePhoneSymbols($input);
    }
}

export function replacePhoneSymbols($input) {

    let $face = $input.querySelector('.replace-phone-symbols-face')
    let $back = $input.querySelector('.replace-phone-symbols-back')


    //console.log("replacePhoneSymbols FOR: ")
    //console.log($input);
    const regex = /[^0-9]/ig;
    let $targetInput = $face.nextElementSibling;
    //console.log($targetInput);

    $face.addEventListener('change', replace);
    $face.addEventListener('input', replace);

    function replace() {
        //console.log('REPLACE')
        let formatedValue = $face.value.replaceAll('+7', '').replaceAll(regex, '');
        if (formatedValue.length > 10)
        {
            setInterfacePhone();
            return;
        }
        $back.value = formatedValue;
        setInterfacePhone();
    }

    $back.addEventListener('change', setInterfacePhone);
    $back.addEventListener('input', setInterfacePhone);

    function setInterfacePhone() {
        let fprmatedValue =  formatPhoneToInterface($back.value);
        //console.log('setInterfacePhone')
        //console.log(fprmatedValue)
        $face.value = fprmatedValue;
    }
}
