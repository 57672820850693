import {tns} from "tiny-slider"

if (document.querySelector(".my-slider") != null) {
    var slider = tns({
        "container": ".my-slider",
        "items": 6,
        "slideBy": "page",
        "mouseDrag": true,
        "swipeAngle": 60,
        "speed": 400,
        "navPosition": 'bottom'
    });

    slider.play();
}
if (document.querySelector(".my-slider2") != null) {
    var slider2 = tns({
        "container": ".my-slider2",
        "items": 6,
        "slideBy": "page",
        "mouseDrag": true,
        "swipeAngle": 60,
        "speed": 400,
        "nav": false,
        responsive: {
            0: {
                items: 2
            },
            460: {
                items: 3
            },
            640: {
                items: 5
            },
            1300: {
                items: 6
            }
        }
    });

    slider2.play();
}
if (document.querySelector(".help-slider") != null) {
    var help_slider = tns({
        "container": ".help-slider",
        "items": 4,
        "slideBy": "page",
        "gutter": 20,
        "mouseDrag": true,
        "swipeAngle": 60,
        "speed": 400,
        "navPosition": 'bottom',
        responsive: {
            0: {
                items: 1
            },
            460: {
                items: 2
            },
            640: {
                items: 3
            },
            1300: {
                items: 4
            }
        }
    });

    help_slider.play();
}

if (document.querySelector(".tiny-carousel") != null) {
    var carousel_slider = tns({
        "container": ".tiny-carousel",
        "items": 3,
        "slideBy": "page",
        "gutter": 10,
        "mouseDrag": true,
        "swipeAngle": 60,
        "speed": 400,
        "navPosition": 'bottom',
        "nav": true,
        "arrowKeys": false,
        responsive: {
            0: {
                items: 1
            },
            640: {
                items: 2
            },
            1255: {
                items: 3
            }
        }
    });

    carousel_slider.play();
}


if (document.querySelector(".album-slider") != null) {
    var album_slider = tns({
        "container": ".album-slider",
        "items": 3,
        "gutter": 25,
        "center": true,
        "autoWidth": true,
        "mouseDrag": true,
        "swipeAngle": 60,
        "navPosition": 'bottom',
        "nav": true,

    });

    album_slider.play();
}
if (document.querySelector(".team-slider") != null) {
    var team_slider = tns({
        "container": ".team-slider",
        "items": 1,
        "gutter": 1,
        "center": true,
        "autoWidth": true,
        "mouseDrag": true,
        "swipeAngle": 60,
        "navPosition": 'bottom',
        "nav": true,
        "arrowKeys": false,
    });

    team_slider.play();
}
if (document.querySelector(".destinations-slider") != null) {
    var destinations_slider = tns({
        "container": ".destinations-slider",
        "items": 1,
        "gutter": 10,
        "center": true,
        "autoWidth": true,
        "mouseDrag": true,
        "swipeAngle": 60,
        "navPosition": 'bottom',
        "nav": true,
        "arrowKeys": false,
    });

    destinations_slider.play();
}
if (document.querySelector(".methodic-slider") != null) {
    var methodic_slider = tns({
        "container": ".methodic-slider",
        "items": 1,
        "gutter": 10,
        "center": true,
        "autoWidth": true,
        "mouseDrag": true,
        "swipeAngle": 60,
        "navPosition": 'bottom',
        "nav": true,
        "arrowKeys": false,
    });

    methodic_slider.play();
}

if (document.querySelector(".doc-slider") != null) {
    var doc_slider = tns({
        "container": ".doc-slider",
        "items": 3,
        "gutter": 10,
        "autoWidth": true,
        "mouseDrag": true,
        "swipeAngle": 60,
        "navPosition": 'bottom',
        "nav": true,
    });

    doc_slider.play();

}


// let order_days_slider_1;
// let order_days_slider_2
// document.addEventListener('order-slider-refresh', e => {
//
//     console.log("ACTIVE SLIDER 1")
//     if (document.querySelector(".order-days-slider-1") != null) {
//         order_days_slider_1 = tns({
//             "container": ".order-days-slider-1",
//             "items": 5,
//             "gutter": 0,
//             "autoWidth": true,
//             "mouseDrag": true,
//             "swipeAngle": 60,
//             "navPosition": 'bottom',
//             "nav": false,
//             0: {
//                 items: 3
//             },
//             380: {
//                 items: 4
//             },
//             600: {
//                 items: 5
//             }
//         });
//
//     }
//
//     console.log("ACTIVE SLIDER 2")
//     if (document.querySelector(".order-days-slider-2") != null) {
//         order_days_slider_2 = tns({
//             "container": ".order-days-slider-2",
//             "items": 5,
//             "gutter": 0,
//             "autoWidth": true,
//             "mouseDrag": true,
//             "swipeAngle": 60,
//             "navPosition": 'bottom',
//             "nav": false,
//             responsive: {
//                 0: {
//                     items: 3
//                 },
//                 380: {
//                     items: 4
//                 },
//                 600: {
//                     items: 5
//                 }
//             }
//         });
//     }
//     // setTimeout(function () {
//     //         window.dispatchEvent(new Event('resize'))
//     //     console.log('timeout RESIZE')
//     //     }, 1000)
//
// })
//
// document.addEventListener('order-slider-regenerate', e => {
//
//     setTimeout(function () {
//         window.dispatchEvent(new Event('resize'))
//         console.log('timeout RESIZE')
//     }, 200)
//
//     if (document.querySelector(".order-days-slider-1") != null) {
//         console.log("REbuild START 1")
//         //order_days_slider_1.destroy()
//         order_days_slider_1.goTo("first")
//     }
//     if (document.querySelector(".order-days-slider-2") != null) {
//         console.log("REbuild START 2")
//         // order_days_slider_2.destroy()
//         order_days_slider_2.goTo("first")
//     }
// })
