let $inputForms = document.querySelectorAll('.input__form');

let inputFocusClass = 'input--focus'
let inputActiveClass = 'input--active'
let inputErrorClass = 'input--error';

if ($inputForms.length > 0) {
    $inputForms.forEach(($input) => {
        $input.onblur = function () {
            let $inputContainer = $input.parentNode;

            $inputContainer.classList.remove(inputFocusClass)
        };

        $input.onfocus = function () {
            let $inputContainer = $input.parentNode;

            if($inputContainer.classList.contains(inputErrorClass)){
                $inputContainer.classList.remove(inputErrorClass);
            }

            $inputContainer.classList.add(inputFocusClass)
            if (!$inputContainer.classList.contains(inputActiveClass)) {
                $inputContainer.classList.add(inputActiveClass)
            }
        };
    })
}
