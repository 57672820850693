window.addEventListener('load', function () {
    let $container = document.querySelector(".carousel__container");
    if ($container) {

        let $track = document.querySelector(".carousel__container-track");
        let $trackItems = document.querySelectorAll(".carousel__container-track .carousel__container-track-item");
        let $map = document.querySelector('.carousel__map')
        let $mapButtons = null
        if ($map !== null) {
            $mapButtons = $map.querySelectorAll('button');
        }
        let $arrowLeft = document.querySelector('.carousel__control-arrow--left');
        let $arrowRight = document.querySelector('.carousel__control-arrow--right');

        let trackWidth = 0;
        let trackItemWidth = 400;
        let sectionsCount = 1;
        let itemsPerSection = 3;
        let itemsCounter = 0;
        let currentSection = 0;
        let sectionTriggerArray = [0];

        window.addEventListener('resize', function (event) {
            console.log("RESIZE")

            if (resizeHandle() == true) {
                console.log("RESIZE Handle")
                sectionsCount = 1;
                trackWidth = 0;
                itemsCounter = 0;
                sectionTriggerArray = [0];

                calculateTrack()
                setTrackWidth(trackWidth + 'px');
                fillMap(sectionsCount);
                activateMap();
            }


        }, true);

        resizeHandle();
        calculateTrack();

        if ($trackItems.length > 0) {
            setTrackWidth(trackWidth + 'px');
            fillMap(sectionsCount);
            activateMap();
            activateDrag();
            activateArrows();
        }

        function calculateTrack() {
            $trackItems.forEach((item) => {
                trackWidth += trackItemWidth;
                itemsCounter++;
                if (itemsCounter >= itemsPerSection) {
                    sectionTriggerArray.push((trackItemWidth * itemsPerSection) * sectionsCount * -1)
                    sectionsCount++;
                    itemsCounter = 0;
                }
            });
            if (itemsCounter !== 0) {
                sectionTriggerArray.push((trackItemWidth * itemsPerSection) * sectionsCount * -1)
                sectionsCount++
            }
        }

        function resizeHandle() {
            if (window.innerWidth <= 329) {
                trackItemWidth = 250;
                itemsPerSection = 1;
                return true;
            }
            if (window.innerWidth <= 601) {
                trackItemWidth = 300;
                itemsPerSection = 1;
                return true;
            }
            if (window.innerWidth <= 768) {
                trackItemWidth = 300;
                itemsPerSection = 2;
                return true;
            } else {
                trackItemWidth = 400;
                itemsPerSection = 3;
                return true;
            }
            //return false;
        }

        function fillMap(sectionsCount) {
            $map.innerHTML = '';
            for (let i = 0; i < sectionsCount - 1; i++) {
                let $mapDot = document.createElement('button')
                $mapDot.dataset.page = i + 1;
                if (i === 0)
                    $mapDot.classList.add('active');

                $map.appendChild($mapDot);
            }
        }

        function setTrackWidth(width) {
            $track.style.width = width;
        }

        function switchMapDot(dotIndex) {
            $mapButtons.forEach(el => {
                if (el.dataset.page == dotIndex + 1) {
                    el.classList.add('active');
                } else {
                    el.classList.remove('active');
                }
            })
        }

        function switchSection(currentSection) {
            let pageStart = (trackItemWidth * itemsPerSection) * currentSection
            let percentage = (pageStart / trackWidth) * 100;
            $track.style.transform = `translateX(-${percentage}%)`
            switchMapDot(currentSection);
        }

        function activateMap() {
            $mapButtons = $map.querySelectorAll('button');
            $mapButtons.forEach(b => {
                b.addEventListener('click', e => {
                    currentSection = e.target.dataset.page - 1

                    switchSection(currentSection);
                })
            })
        }

        function activateDrag() {

            let pressed = false;
            let startX = 0;
            let x = 0;
            let defaultTransition = $track.style.transition;

            $container.addEventListener('mousedown', (event) => {
                pressed = true;
                $container.style.cursor = 'grabbing';
                defaultTransition = $track.style.transition
                $track.style.transition = 'none';

                startX = event.clientX;
            })

            $container.addEventListener('mousemove', (event) => {
                if (!pressed) {
                    return
                }
                event.preventDefault();

                if (currentSection > 0) {
                    x = event.clientX - ((trackItemWidth * itemsPerSection) * currentSection);
                } else {
                    x = event.clientX;
                }

                console.log('StartX: ' + startX + " - X:" + x);

                $track.style.transform = `translateX(${x - startX}px)`
            });

            $container.addEventListener('mouseup', () => {
                $container.style.cursor = 'grab';
                pressed = false;
                $track.style.transition = defaultTransition;

                let currentStop = x - startX;
                let closest = sectionTriggerArray.reduce(function (prev, curr) {
                    return (Math.abs(curr - currentStop) < Math.abs(prev - currentStop) ? curr : prev);
                });

                //$track.style.transform = `translateX(${closest}px)`

                console.log("CLOSEST:" + closest);
                console.log("INDEX:" + sectionTriggerArray.indexOf(closest))
                currentSection = sectionTriggerArray.indexOf(closest)
                switchSection(currentSection)
            });

            $container.addEventListener("mouseenter", () => {
                $container.style.cursor = "grab";
            });

        }

        function activateArrows() {
            if ($arrowLeft !== undefined) {
                $arrowLeft.addEventListener('click', () => {
                    console.log("LEFT:", currentSection)
                    if (currentSection > 0) {
                        currentSection--;
                        switchSection(currentSection);
                    }

                })
            }
            if ($arrowRight !== undefined) {
                $arrowRight.addEventListener('click', () => {
                    console.log("RIGHT:", currentSection)
                    if (currentSection < (sectionsCount - 1)) {
                        currentSection++;
                        switchSection(currentSection);
                    }
                })
            }
        }

    }
})

