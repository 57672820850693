let $wrapBtn = document.querySelector('.specialist-order-card__block-wrap-btn');
let $wrapperContainer = document.querySelector('.specialist-order');

let activeClass = 'specialist-order--wrap-active'

if ($wrapBtn){
    $wrapBtn.addEventListener('click', e=>{
        wrapContainers()
    })
    function wrapContainers(){
        if($wrapperContainer.classList.contains(activeClass)){
            $wrapperContainer.classList.remove(activeClass)
        }
        else{
            $wrapperContainer.classList.add(activeClass)
        }
    }
}
