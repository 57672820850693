appointmentListenerStart();

function appointmentListenerStart() {
    let $appointmentModal = document.querySelector('#appointment')
    if ($appointmentModal) {
        let $appointmentButtons = document.querySelectorAll('[data-modal-open="appointment"]')
        let $appointmentModalInputs = $appointmentModal.querySelectorAll('.input__form');
        let $appointmentModalSpecInput = $appointmentModal.querySelector('.input__form-spec');
        $appointmentButtons.forEach($btn => {
           // console.log($btn)
            $btn.addEventListener('click', event => {
                $appointmentModalInputs.forEach($appointmentInput => {
                    $appointmentInput.value = null;
                })
                console.log(event.currentTarget.dataset.spec);
                $appointmentModalSpecInput.value = event.currentTarget.dataset.spec
            })
        })
    }
}

