let $prodocRatings = document.querySelectorAll('.specialist-prodoc-rating')

if ($prodocRatings.length > 0){

    $prodocRatings.forEach($prodocRating=>{

        //console.log($prodocRating.dataset.url);

        // let url = '/prodoc/rating?url=' + $prodocRating.dataset.url
        // axios.get(url)
        //     .then((response) => {
        //         console.log('RESP PRODOC RATING')
        //         console.log(response.data);
        //         //$prodocRating.innerText = response.data;
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     });
    })



}