let $landingNav = document.querySelector('.specialist-landing-nav')
let $welcomeContainer = document.querySelector('.specialist-landing-welcome');
let $landingContainer = document.querySelector('.specialist-landing');

let landingNAvFixedClass = 'specialist-landing-nav--fixed'
let landingAfterFixedClass = 'specialist-landing--after-fixed'

if ($landingNav && $welcomeContainer) {
    window.addEventListener('scroll', () => {
        let welcomeRect = $welcomeContainer.getBoundingClientRect()

        if (welcomeRect.bottom < 0) {
            //console.log('CCCCCCCC')
            if (!$landingNav.classList.contains(landingNAvFixedClass)) {
                //console.log('AAAAAAAA')
                $landingNav.classList.add(landingNAvFixedClass)
                $landingContainer.classList.add(landingAfterFixedClass)
            }
        } else {
            if ($landingNav.classList.contains(landingNAvFixedClass)) {
                //console.log('BBBBBBBBBB')
                $landingNav.classList.remove(landingNAvFixedClass)
                $landingContainer.classList.remove(landingAfterFixedClass)
            }
        }

    });
}

let $orderRedirectBtns = document.querySelectorAll('.order-redirect-check');
let orderRedirect = false;
let specialistId = 0;

if ($orderRedirectBtns[0])
{
    specialistId = $orderRedirectBtns[0].dataset.id;

    let url = '/specialist/' + specialistId + '/free-dates'
    axios.get(url)
        .then((response) => {
            console.log()
            if (response.data != null && Object.keys(response.data).length > 0){
                orderRedirect = true;
            }
        })
        .catch((error) => {
        });
}
$orderRedirectBtns.forEach($orderRedirectBtn => {
    $orderRedirectBtn.addEventListener('click', () => {
        console.log(orderRedirect)


        if (!orderRedirect){
            modals.open("appointment");
        }
        else {
            window.location = '/specialist/'+specialistId+'/order?from=landing'
        }
    });

})





