import {activateModalTip} from "../modal/modal-tip";

let $navWrapList = document.querySelectorAll('.header__navbar-inner-panel-nav-wrapper')
let $navWrapListM = document.querySelectorAll('.header__navbar-m-nav-item--wrapper')

let navWrapListOpenClass = 'header__navbar-inner-panel-nav-wrapper--open'
let navWrapListOpenClassM = 'header__navbar-m-nav-item--open'



$navWrapList.forEach(($navWrap, key) => {
    let $overContainer = $navWrap.querySelector('.header__navbar-inner-panel-nav-wrapper-container')
    $navWrap.dataset.active = '0';
    $navWrap.dataset.name = key;


    $navWrap.addEventListener('mouseenter', e => {
        console.log(e.currentTarget)

        if ($navWrap.dataset.active != '1'){
            $navWrap.dataset.active = '1';
            console.log('active: ' +  $navWrap.dataset.name)
            refocusNavWrapper($navWrapList, $navWrap,  false)

            $navWrapList.forEach($navWrapOther=>{
                if ($navWrapOther != $navWrap)
                {
                    $navWrap.dataset.active = '0';
                }
            })

        }
    })

    $overContainer.addEventListener('mouseleave', e => {
        if (e.target != $overContainer)
            return

        console.log('disactive: ' +  $navWrap.dataset.name)
        refocusNavWrapper($navWrapList, $navWrap, true)
        $navWrap.dataset.active = '0';
    })


    $navWrap.addEventListener('click', event => {
        if ($navWrap.classList.contains(navWrapListOpenClass)) {
            refocusNavWrapper($navWrapList, $navWrap,  true)
        } else {
            refocusNavWrapper($navWrapList, $navWrap)
        }
    })
})

function refocusNavWrapper($navWrapList, $navWrap, is_open = false){
    $navWrapList.forEach($navWrapItem=>{
        $navWrapItem.classList.remove(navWrapListOpenClass);
    })
    if (!is_open)
    {
        $navWrap.classList.add(navWrapListOpenClass)
    }

}

$navWrapListM.forEach($navWrap => {
    $navWrap.querySelector('.header__navbar-m-nav-item-title').addEventListener('click', event => {
        if ($navWrap.classList.contains(navWrapListOpenClassM)) {
            $navWrap.classList.remove(navWrapListOpenClassM)
        } else {
            $navWrap.classList.add(navWrapListOpenClassM)
        }
    })
})
