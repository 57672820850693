import {getCookie} from "../cookie/cookie";
import {sendcbToCRM} from "../mailform/feedback-send";
import {phoneVerification} from "../phone-verification/phone-verification";

let $orderPageFirst = document.querySelector('.specialist-order-info-first')
let $orderPageSecond = document.querySelector('.specialist-order-info-second')
let $orderPageThird = document.querySelector('.specialist-order-info-third')

let $orderContainer = document.querySelector('.specialist-order');

let $nextBtn = document.querySelector('.specialist-order-card__block-price-btn');

let activePageClass = 'specialist-order-info--active';
let activePromoClass = 'specialist-order-card__promo--active';

let bankCardData = null;

let orderData = {
    'visit': 'online',
    'direction': 1, // 1 - Психолог 2 - Дефектолог
    'date': null,
    'phone': null,
    'name': null,
    'email': null,
    'payType': 0, // 1 - card 0 - afterpay
    'price': 0,
    'specialist': 0,
    'specialistName': '',
    'timezone': 0,
    'fromLanding': false
}
console.log('START ORDER')


//etaps
if ($orderPageFirst && $orderPageSecond && $orderPageThird) {
    let $changeDateBtn = document.querySelector('.specialist-order-card__block-content-link')
    let $realChangeDateBtn = document.querySelector('.more-date-btn')

    $changeDateBtn.addEventListener('click', e => {
        $realChangeDateBtn.click();
    })

    let $promoContainer = document.querySelector('.specialist-order-card__promo');


    // if (document.querySelector('#orderTEST'))
    //{
    console.log('START ORDER TEST')
    const $phoneVerificationModal = document.querySelector('#phone-verification');
    const $phone = document.querySelector('.order-input-phone')

    $phone.addEventListener('change', e => {
        console.log('PHONE CHANGE');
        console.log($phone.value)
        sendLog({event: 'Форма 3 шага --- телефон --- до верификации', phone: $phone.value});
    })

    function sendLog(data) {
        fetch('/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(data => {
            console.log(data)
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const phoneTools = phoneVerification(
        {
            $wrapper: $phoneVerificationModal,
            $startPhone: $phone,
            callback: (success) => {
                if (success) {
                    console.log("Проверка прошла успешно!");
                    $orderContainer.classList.remove('specialist-order--wrap-active')
                    $orderContainer.classList.add('specialist-order--wrap-payment')
                    nextPage(2);
                    $nextBtn.dataset.page = '3';
                    $nextBtn.innerText = 'Записаться'
                    $promoContainer.classList.add(activePromoClass);
                    //someOtherFunction(); // Вызывается ваша другая функция

                    ct_sendGoal($nextBtn.dataset.page);

                    if (orderData.fromLanding == false) {
                        ym(88810526, 'reachGoal', 'order-page-create');
                    } else {
                        ym(88810526, 'reachGoal', 'order-page-landing-create')
                    }

                    let timezone = getCookie('timezone');
                    if (timezone) {
                        orderData.timezone = timezone;
                    } else {
                        orderData.timezone = 1;
                    }

                    let MSK = ''
                    if (orderData.timezone > 0) {
                        MSK = ' +' + orderData.timezone;
                    } else if (orderData.timezone < 0) {
                        MSK = ' ' + orderData.timezone;
                    }

                    console.log(orderData)

                    let crmTitle = ''
                    if (orderData.fromLanding == false) {
                        crmTitle = '5. Форма 4 шага'
                    } else {
                        crmTitle = '5. Форма 4 шага(лендинг)'
                    }

                    let $crmDesc = 'Клиент: ' + orderData.name + '\n' +
                        'Телефон: ' + orderData.phone + '\n' +
                        'Почта: ' + orderData.email + '\n' +
                        'Специалист: ' + orderData.specialistName + '\n' +
                        'Дата: ' + orderData.date + ' (MSK' + MSK + ')\n';

                    let $crmData = {
                        'name': crmTitle,
                        'desc': $crmDesc
                    }

                    sendLog({event: 'Форма 3 шага --- телефон --- после верификации', phone: $phone.value});
                    sendcbToCRM($crmData, false);
                    $lastPayRadio.click();
                }
            }
        });

    //ОБРАБОТКА КАПЧИ
    console.log('START')
    //$nextBtn.classList.add('btn-blue--disable')

    // $nextBtn.addEventListener('click', e => {
    //     if (window.captcha_verify) {
    //         //$modalPhone.innerText = $phone.value;
    //         phoneTools.openModal();
    //         //Вызвать тут OpenModal
    //     }
    // })

    //}

    $nextBtn.addEventListener('click', e => {

        if ($nextBtn.dataset.page == '1') {
            if (checkDateSelection()) {
                getVisitData();
                getSpecialistData();
                showVisitInfo();
                nextPage(1);
                ct_sendGoal($nextBtn.dataset.page);
                $nextBtn.dataset.page = '2';
                checkBtn(checkInputs(false))
            }
        } else if ($nextBtn.dataset.page == '2') {
            if (checkInputs()) {

                // if (document.querySelector('#orderTEST'))
                // {
                if (window.captcha_verify) {
                    console.log("Проверка прошла успешно!");
                    $orderContainer.classList.remove('specialist-order--wrap-active')
                    $orderContainer.classList.add('specialist-order--wrap-payment')
                    nextPage(2);
                    $nextBtn.dataset.page = '3';
                    $nextBtn.innerText = 'Записаться'
                    $promoContainer.classList.add(activePromoClass);
                    //someOtherFunction(); // Вызывается ваша другая функция

                    ct_sendGoal($nextBtn.dataset.page);

                    if (orderData.fromLanding == false) {
                        ym(88810526, 'reachGoal', 'order-page-create');
                    } else {
                        ym(88810526, 'reachGoal', 'order-page-landing-create')
                    }

                    let timezone = getCookie('timezone');
                    if (timezone) {
                        orderData.timezone = timezone;
                    } else {
                        orderData.timezone = 1;
                    }

                    let MSK = ''
                    if (orderData.timezone > 0) {
                        MSK = ' +' + orderData.timezone;
                    } else if (orderData.timezone < 0) {
                        MSK = ' ' + orderData.timezone;
                    }

                    console.log(orderData)

                    let crmTitle = ''
                    if (orderData.fromLanding == false) {
                        crmTitle = '5. Форма 4 шага'
                    } else {
                        crmTitle = '5. Форма 4 шага(лендинг)'
                    }

                    let $crmDesc = 'Клиент: ' + orderData.name + '\n' +
                        'Телефон: ' + orderData.phone + '\n' +
                        'Почта: ' + orderData.email + '\n' +
                        'Специалист: ' + orderData.specialistName + '\n' +
                        'Дата: ' + orderData.date + ' (MSK' + MSK + ')\n';

                    let $crmData = {
                        'name': crmTitle,
                        'desc': $crmDesc
                    }

                    sendLog({event: 'Форма 3 шага --- телефон --- после верификации', phone: $phone.value});
                    sendcbToCRM($crmData, false);
                    $lastPayRadio.click();


                    //phoneTools.openModal();
                }
                // }
                // else{
                //     $orderContainer.classList.remove('specialist-order--wrap-active')
                //     $orderContainer.classList.add('specialist-order--wrap-payment')
                //     nextPage(2);
                // }
            }
        } else if ($nextBtn.dataset.page == '3') {
            ct_sendGoal($nextBtn.dataset.page);
            getPaymentData();
            getPriceData();
            createOrder();
            //redirectWithData();
            //sendCardTransaction()
        } else {
        }
    })

    function moveToFinalOrder() {

    }

    function ct_sendGoal(orderStep) {
        try {
            ct('goal', `record_specialist_step${orderStep}`);
        } catch (error) {
        }
    }

    function nextPage(page) {
        if (page == 1) {
            $orderPageFirst.classList.remove(activePageClass)
            $orderPageSecond.classList.add(activePageClass)
        }
        if (page == 2) {
            $orderPageSecond.classList.remove(activePageClass)
            $orderPageThird.classList.add(activePageClass)
        }
    }

    function createOrder() {

        let url = '/order/create'

        $nextBtn.innerText = ''
        $nextBtn.classList.add('btn-load');

        let timezone = getCookie('timezone');
        if (timezone) {
            orderData.timezone = timezone;
        } else {
            orderData.timezone = 1;
        }

        console.log('ORDER DATA');
        console.log(orderData);

        axios.post(url, {
            'orderData': orderData
        }).then((response) => {

            if (response.data) {
                console.log(response.data);

                if (orderData.payType == 1) {
                    sendReservationToCRM(orderData)
                    //window.location = window.location.origin + '/order/complete?order=' + order_id;
                    //sendAproveToCRM(orderData)
                    //sendOrderToCRM(response.data);
                } else if (orderData.payType == 0) {
                    sendOrderToCRM(response.data);
                }
            }
            //$nextBtn.innerText = 'Записаться'
            //
        }).catch((error) => {
            console.log(error)
            $nextBtn.classList.remove('btn-load');
            $nextBtn.innerText = 'Записаться'
        });
    }

    function sendReservationToCRM($data) {

        axios({
            method: 'POST',
            url: `${window.location.origin}/order-reserv/crm-send`,
            data: $data,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);
        }).catch((err) => {
            console.log(err)
        });
    }

    function sendAproveToCRM($data) {
        axios({
            method: 'POST',
            url: `${window.location.origin}/order-aprove/crm-send`,
            data: $data,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);
        }).catch((err) => {
            console.log(err)
        });
    }

    function sendOrderToCRM(order_id) {
        let url = '/order/' + order_id + '/crm-send';

        let $data = [];

        $data['cookie'] = {
            'utmSource': getCookie('utm_source'),
            'utmMedium': getCookie('utm_medium'),
            'utmCampaign': getCookie('utm_campaign'),
            'utmTerm': getCookie('utm_content'),
            'utmContent': getCookie('utm_term'),
        };

        axios({
            method: 'POST',
            url: url,
            data: $data,
        }).then((response) => {
            if (response.data) {
                console.log(response.data);

                redirectWithData(order_id);
                //redirectWithData(response.data)
            }
            //$nextBtn.innerText = 'Записаться'
            //
        }).catch((error) => {
            console.log(error)
            $nextBtn.classList.remove('btn-load');
            $nextBtn.innerText = 'Записаться'
        });

    }

    function redirectWithData(order_id) {
        let redirect = '/thank-you'
        let reqData = {
            order: null
        };

        reqData.order = order_id;

        axios({
            method: 'POST',
            url: `${window.location.origin}/order/send`,
            data: reqData,
        }).then((response) => {
            console.log("OK");
            console.log(response.data);

            //window.location = window.location.origin + redirect

            let url = window.location.origin + '/order/complete?order=' + order_id;
            window.location = url;
            console.log(url)
        }).catch((err) => {
            console.log(err)
            console.log("Заполните данные")
        });

        //let url = window.location.origin + '/order/complete?order=' + order_id;
        //window.location = url;
        //console.log(url)
    }

    document.addEventListener("get-card-data", function (e) {
        bankCardData = e.detail.bankCardData
    });

    document.addEventListener("change-order-price", function (e) {
        console.log('ORDER PRICE CHANGED')
        orderData.price = e.detail.newPrice;
        let $priceInput = document.querySelector('.order-data-price')
        $priceInput.innerHTML = orderData.price + ' <span className="specialist-order-card__block-price-price-main--s">₽</span>';
    })

    document.addEventListener("change-order-from-page", function (e) {
        orderData.fromLanding = true;
        console.log('dispatch from event----------')
    })

    function afterpayCheck(bankResp) {

        let bankRespData = bankResp['data'];

        if (bankRespData['Success'] == true) {

        }
    }


    //page 1
    let $curDate = document.querySelector('.specialist-order-card-block-date')

    document.addEventListener('orderDataDateChange', e => {

        let timezone = getCookie('timezone');
        if (timezone) {
            orderData.timezone = timezone;
        } else {
            orderData.timezone = 1;
        }

        orderData.date = $curDate.dataset.date
        checkBtn(checkDateSelection());
    })
    checkBtn(checkDateSelection());

    function checkDateSelection() {
        if ($curDate.innerText.includes('Не указана')) {
            return false;
        }
        orderData.date = $curDate.dataset.date
        return true;
    }

    function getVisitData() {
        let $curDirection = document.querySelector('.radio-specialist-direction input:checked');
        let $curVisit = document.querySelector('.radio-specialist-visit input:checked');

        if ($curDirection) {
            orderData.direction = $curDirection.dataset.value
        }

        if ($curVisit) {
            orderData.visit = $curVisit.dataset.value
        }

    }

    function showVisitInfo() {
        let $visitInfoContainer = document.querySelector('.specialist-order-card__block-content-visit-info-container')
        if ($visitInfoContainer) {

            let $directionInfo = $visitInfoContainer.querySelector('.specialist-order-card-block-direction')
            let $visitInfo = $visitInfoContainer.querySelector('.specialist-order-card-block-visit')

            let $curDirection = document.querySelector('.radio-specialist-direction input:checked');
            let $curVisit = document.querySelector('.radio-specialist-visit input:checked');


            if ($directionInfo && $curDirection) {
                $directionInfo.innerText = $curDirection.dataset.title
            } else {
                $directionInfo.innerText = 'Психолог'
            }

            if ($visitInfo && $curVisit) {
                $visitInfo.innerText = $curVisit.dataset.title
            } else {
                $directionInfo.innerText = 'Онлайн'
            }

            $visitInfoContainer.style = 'display: flex';


        }
    }

    function checkBtn(check) {
        if (!check) {
            $nextBtn.classList.add('btn--disable')
        } else {
            $nextBtn.classList.remove('btn--disable')
        }
    }

    //page 2
    let $phoneInputFace = document.querySelector('.replace-phone-symbols-face')
    let $phoneInput = document.querySelector('.replace-phone-symbols-back')
    let $nameInput = document.querySelector('.order-input-name')
    let $emailInput = document.querySelector('.order-input-email')

    let $yearCheck = document.querySelector('.order-check-year')
    let $ruleCheck = document.querySelector('.order-check-rule')

    let $inputMessage = document.querySelector('.error-text-inputs')
    let $checkMessage = document.querySelector('.error-text-checkbox')

    function checkInputs(marked = true) {
        let check = true;

        let inputs = true;
        let checkboxes = true;


        if (marked) {
            if ($phoneInput.value.length < 10) {
                check = false;
                inputs = false
                $phoneInputFace.classList.add('input__form--error')

            } else {
                $phoneInputFace.classList.remove('input__form--error')
            }
            if ($nameInput.value == '') {
                check = false;
                inputs = false
                $nameInput.classList.add('input__form--error')
            } else {
                $nameInput.classList.remove('input__form--error')
            }
            // if ($yearCheck.checked == false) {
            //     check = false;
            //     checkboxes = false;
            //     $yearCheck.classList.add('checkbox__input--error')
            // } else {
            //     $yearCheck.classList.remove('checkbox__input--error')
            // }
            if ($ruleCheck.checked == false) {
                check = false;
                checkboxes = false;
                $ruleCheck.classList.add('checkbox__input--error')
            } else {
                $ruleCheck.classList.remove('checkbox__input--error')
            }

            // if (document.querySelector('#orderTEST'))
            // {
            if (!window.captcha_verify) {
                check = false;
                checkboxes = false;
                $ruleCheck.classList.add('checkbox__input--error')
            } else {
                $ruleCheck.classList.remove('checkbox__input--error')
            }
            //}

            if (inputs) {
                $inputMessage.classList.remove('specialist-order-info__contacts-error-text--active')
            } else {
                $inputMessage.classList.add('specialist-order-info__contacts-error-text--active')
            }
            if (checkboxes) {
                $checkMessage.classList.remove('specialist-order-info__contacts-error-text--active')
            } else {
                $checkMessage.classList.add('specialist-order-info__contacts-error-text--active')
            }
        } else {
            if ($phoneInput.value.length < 10) {
                console.log('$phoneInput')
                check = false;
            }
            if ($nameInput.value == '') {
                console.log('$nameInput')
                check = false;
            }
            // if ($yearCheck.checked == false) {
            //     console.log('$yearCheck')
            //     check = false;
            // }
            if ($ruleCheck.checked == false) {
                console.log('$ruleCheck')
                check = false;
            }
        }

        orderData.phone = $phoneInput.value;
        orderData.email = $emailInput.value;
        orderData.name = $nameInput.value;

        return check;
    }

    $phoneInputFace.addEventListener('blur', e => {
        checkBtn(checkInputs(false));
    })
    $nameInput.addEventListener('blur', e => {
        checkBtn(checkInputs(false));
    })
    // $yearCheck.addEventListener('change', e => {
    //     checkBtn(checkInputs(false));
    // })
    $ruleCheck.addEventListener('change', e => {
        checkBtn(checkInputs(false));
    })

    //page 3
    let $afterpayBlock = document.querySelector('.specialist-order-info-afterpay-block')
    let $afterpayBlockHide = document.querySelector('.specialist-order-info-afterpay-block-h')

    let $payBlock = document.querySelector('.specialist-order-info-pay-block');
    let $firstPayRadio = document.querySelector('.specialist-order-info-radio-pay-first')
    let $lastPayRadio = document.querySelector('.specialist-order-info-radio-pay-last')


    if ($firstPayRadio) {
        $firstPayRadio.addEventListener('click', e => {
            $payBlock.classList.remove('hidden');
            $afterpayBlockHide.classList.remove('hidden');
            $afterpayBlock.classList.add('hidden');
            $nextBtn.innerText = 'Записаться'
        })
    }
    if ($lastPayRadio) {
        $lastPayRadio.addEventListener('click', e => {
            $payBlock.classList.add('hidden');
            $afterpayBlockHide.classList.add('hidden');
            $afterpayBlock.classList.remove('hidden');
            $nextBtn.innerText = 'Записаться'
        })
    }


    function getPaymentData() {
        let $curPayment = document.querySelector('.specialist-order-payment input:checked');

        if ($curPayment) {
            orderData.payType = $curPayment.dataset.value
        }

    }

    function getPriceData() {
        let $priceData = document.querySelector('.order-data-price')
        orderData.price = $priceData.innerText.replaceAll('₽', '');
    }

    function getSpecialistData() {

        let $specialistData = document.querySelector('.specialist-order-specialist')
        orderData.specialist = $specialistData.dataset.specialist;
        orderData.specialistName = $specialistData.dataset.name;
    }
}



