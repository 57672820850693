//
//
//
// let activeImageClass = 'image-selector__container-img--active';
// let leftImageSelectorBtnClass = 'image-selector__controller--left';
// let RightImageSelectorBtnClass = 'image-selector__controller--right';
// let ImageSelectorLinkClass = 'image-selector__link'
// let ImageSelectorImageStorageClass = 'image-selector__data-storage'
//
// let $leftImageSelectorBtn = document.querySelector('.' + leftImageSelectorBtnClass);
// let $RightImageSelectorBtn = document.querySelector('.' + RightImageSelectorBtnClass);
// let $ImageSelectorLinkList = document.querySelectorAll('.' + ImageSelectorLinkClass)
// let $ImageSelectorContainer = document.querySelector('.image-selector__container');
// //let $imagesList = null;
//
// $ImageSelectorLinkList.forEach($ImageSelectorLink=>{
//     $ImageSelectorLink.addEventListener('click', event=>{
//         console.log(event.currentTarget);
//         $ImageSelectorContainer.innerHTML = ''
//         let $imagesList = event.currentTarget.querySelectorAll('.' + ImageSelectorImageStorageClass + '> img')
//         $imagesList.forEach(($image,i)=>{
//             if(i === 0)
//             {
//                 $image.classList.add(activeImageClass)
//             }
//             $ImageSelectorContainer.appendChild($image);
//         })
//         console.log($imagesList)
//         //$ImageSelectorContainer.innerHTML = event.currentTarget.querySelectorAll('.' + ImageSelectorImageStorageClass)
//         modals.open('modal-images-selector');
//     })
//
// })
//
// function init() {
//     this.$leftImageSelectorBtn.addEventListener('click', event => {
//
//     })
//     this.$RightImageSelectorBtn.addEventListener('click', event => {
//
//     })
// }
//
