let $daypicker = document.querySelector('.daypicker');
let mnth = [
    '',
    'янв',
    'февр',
    'март',
    'апр',
    'май',
    'июнь',
    'июль',
    'авг',
    'сент',
    'окт',
    'нояб',
    'дек',
];


if ($daypicker) {
    console.log('DAYPICKER FIND')
    let $acceptBtn = document.querySelector('.daypicker-btn');

    console.log('DP BTN')
    console.log($acceptBtn);

    let curDate = new Date();
    let curYear = curDate.getFullYear();
    let curMonth = curDate.getMonth();
    let month = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    let picks = 0;
    let firstPick = null;
    let secondPick = null;

    Daypicker($daypicker, 3);

    function Daypicker($daypicker, monthCount) {
        console.log('DAYPICKER START')

        for (let i = 0; i < monthCount; i++) {
            $daypicker.appendChild(buildMonth(i));
        }


        getTimesFromCrm();
        $acceptBtn.addEventListener('click', (e) => {
            dispatchFilter();
        })
    }


    function dispatchFilter() {
        console.log('AAAA');
        let event = new CustomEvent("refreshDatepicker");
        document.dispatchEvent(event);
        console.log('aaaa')
    }

    function buildMonth(monthOrder) {
        let $container = document.createElement('div');
        $container.classList.add('daypicker__table')
        let $containerBody = document.createElement('tbody');
        $containerBody.classList.add('daypicker__tbody')
        let $containerHead = document.createElement('thead');
        $containerHead.classList.add('daypicker__thead')


        let Dlast = new Date(curYear, (curMonth + 1) + monthOrder, 0).getDate();
        let D = new Date(curYear, curMonth + monthOrder, Dlast);
        let DNlast = new Date(D.getFullYear(), D.getMonth(), Dlast).getDay();
        let DNfirst = new Date(D.getFullYear(), D.getMonth(), 1).getDay();
        let calendar = '<tr>';

        let calendarMonth = (D.getMonth() + 1) < 10 ? ('0' + (D.getMonth() + 1)) : (D.getMonth() + 1)

        if (monthOrder == 1) {
            $containerHead.innerHTML = '      <tr class="nav">\n' +
                '                                <td colspan="7">\n' +
                '                                <td class="m-show" data-month="' + calendarMonth + '" colspan="4">Выбрать весь месяц \n' +
                '                                <td class="next m-hide">\n' +
                '                            <tr>\n' +
                '                                <td>Пн\n' +
                '                                <td>Вт\n' +
                '                                <td>Ср\n' +
                '                                <td>Чт\n' +
                '                                <td>Пт\n' +
                '                                <td>Сб\n' +
                '                                <td>Вс'
        } else {
            $containerHead.innerHTML = '      <tr class="nav">\n' +
                '                                <td colspan="7">\n' +
                '                                 <td class="m-show" data-month="' + calendarMonth + '" colspan="4">Выбрать весь месяц \n' +
                '                            <tr>\n' +
                '                                <td>Пн\n' +
                '                                <td>Вт\n' +
                '                                <td>Ср\n' +
                '                                <td>Чт\n' +
                '                                <td>Пт\n' +
                '                                <td>Сб\n' +
                '                                <td>Вс'
        }


        $container.appendChild($containerBody);
        $container.appendChild($containerHead);


        if (DNfirst != 0) {
            for (var i = 1; i < DNfirst; i++) calendar += '<td>';
        } else {
            for (var i = 0; i < 6; i++) calendar += '<td>';
        }
        for (var i = 1; i <= Dlast; i++) {
            let calendarDay = i < 10 ? ('0' + i) : i

            if (i == new Date().getDate() && D.getFullYear() == new Date().getFullYear() && D.getMonth() == new Date().getMonth()) {
                calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '" data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container today disable">' + i;
            } else if ((i <= new Date().getDate() && D.getFullYear() <= new Date().getFullYear() && D.getMonth() <= new Date().getMonth()) || (D.getMonth() < new Date().getMonth() && D.getFullYear() <= new Date().getFullYear())) {
                calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '" data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container disable">' + i;
            } else {
                calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '"  data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container disable">' + i;
            }
            if (new Date(D.getFullYear(), D.getMonth(), i).getDay() == 0) {
                calendar += '<tr>';
            }
        }

        for (var i = DNlast; i < 7; i++) calendar += '<td>&nbsp;';


        $container.querySelector('tbody').innerHTML = calendar;
        $container.querySelector('thead td:nth-child(1)').innerHTML = month[D.getMonth()];
        $container.querySelector('thead td:nth-child(1)').dataset.month = D.getMonth();
        $container.querySelector('thead td:nth-child(1)').dataset.year = D.getFullYear();
        if ($container.querySelectorAll('tbody tr').length < 6) {  // чтобы при перелистывании месяцев не "подпрыгивала" вся страница, добавляется ряд пустых клеток. Итог: всегда 6 строк для цифр
            $container.querySelector('tbody').innerHTML += '<tr><td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;';
        }

        if (monthOrder == 1) {
            $container.querySelector('thead td.next').addEventListener('click', e => {
                if ($daypicker.classList.contains('daypicker--next')) {
                    e.currentTarget.style.transform = 'none';
                    e.currentTarget.style.left = 'auto';
                    $daypicker.classList.remove('daypicker--next');
                } else {
                    e.currentTarget.style.transform = 'rotate(180deg)';
                    e.currentTarget.style.left = '10px';
                    $daypicker.classList.add('daypicker--next');
                }

            })
        }

        let $choseAllMonthBtn = $container.querySelector('thead td:nth-child(2)')
        $choseAllMonthBtn.addEventListener('click', e => {
            let $curDates = $daypicker.querySelectorAll('.container[data-month="' + $choseAllMonthBtn.dataset.month + '"]')

            picks = 0;
            //TODO: refactor shit
            let isFirst = false;
            $curDates.forEach($d => {
                if (!$d.classList.contains('disable') && !isFirst) {
                    isFirst = true;
                    $d.click();
                }
            })

            $curDates = [...$curDates];
            isFirst = false;
            $curDates.reverse().forEach($d => {
                if (!$d.classList.contains('disable') && !isFirst) {
                    isFirst = true;
                    $d.click();
                }
            })
        })


        return $container;
    }

    //TODO add month order
    function getTimesFromCrm() {
        //TODO CRM new
        //return false
        let requestMonth = curMonth + 1 == 13 ? 1 : curMonth + 1;
        console.log('DAYPICKER')
        let url = '/specialists/free-date-picker?month=' + requestMonth + '&year=' + curYear;
        axios.get(url)
            .then((response) => {
                console.log('DAYPICKER DATA___')
                console.log(response.data);
                // refreshBtns(false)
                if (response.data != null) {
                    fillFreeDates(response.data);
                }

            })
            .catch((error) => {
                console.log(error)
                //getTimesFromCrm();
            });

    }

    function choseDay($curDate) {
        if ($curDate.classList.contains('disable')) {
            return
        }
        ++picks;

        if ($acceptBtn.classList.contains('btn--disable'))
            $acceptBtn.classList.remove('btn--disable')


        let $dates = $daypicker.querySelectorAll('td.container')

        console.log(picks);


        if (picks == 1) {
            $dates.forEach($day => {
                if ($day.classList.contains('current')) {
                    $day.classList.remove('current');
                }
                if ($day.classList.contains('picked')) {
                    $day.classList.remove('picked');
                }
                if ($day === $curDate) {
                    $day.classList.add('current')
                    console.log('m')
                    console.log($day.dataset.month)
                    console.log(mnth[parseInt($day.dataset.month, 10)])
                    firstPick = $day.dataset.date;
                    $daypicker.dataset.first = firstPick;
                    $daypicker.dataset.first_day = $day.dataset.day;
                    $daypicker.dataset.first_month = mnth[parseInt($day.dataset.month, 10)];

                    $daypicker.dataset.second = null;
                    $daypicker.dataset.second_day = null;
                    $daypicker.dataset.second_month = null;


                    $acceptBtn.innerText = 'Выбрать ' + $day.dataset.day + ' ' + $daypicker.dataset.first_month;
                    //$calendar.dataset.day = $currentDay.dataset.year + '-' + $currentDay.dataset.month + '-' + $currentDay.dataset.day
                }
            })
        }
        if (picks == 2) {
            picks = 0;
            $dates.forEach($day => {
                if ($day.classList.contains('current')) {
                    if ($day.dataset.date != firstPick)
                        $day.classList.remove('current');
                }
                if ($day === $curDate) {
                    $day.classList.add('current')
                    console.log('m')
                    console.log($day.dataset.month)
                    console.log(mnth[parseInt($day.dataset.month, 10)])
                    secondPick = $day.dataset.date;
                    $daypicker.dataset.second = secondPick;
                    $daypicker.dataset.second_day = $day.dataset.day;
                    $daypicker.dataset.second_month = mnth[parseInt($day.dataset.month, 10)];
                    //mnth

                    if (firstPick > secondPick) {
                        $acceptBtn.innerText = 'Выбрать ' + $daypicker.dataset.second_day + ' ' + $daypicker.dataset.second_month + ' — ' + $daypicker.dataset.first_day + ' ' + $daypicker.dataset.first_month;
                    } else {
                        $acceptBtn.innerText = 'Выбрать ' + $daypicker.dataset.first_day + ' ' + $daypicker.dataset.first_month + ' — ' + $daypicker.dataset.second_day + ' ' + $daypicker.dataset.second_month;
                    }


                    //$calendar.dataset.day = $currentDay.dataset.year + '-' + $currentDay.dataset.month + '-' + $currentDay.dataset.day
                }
            })


            let datesRange
            if (firstPick > secondPick) {
                datesRange = getDatesInRange(new Date(secondPick), new Date(firstPick));
            } else {
                datesRange = getDatesInRange(new Date(firstPick), new Date(secondPick));
            }

            datesRange.forEach(date => {
                let $curRangeDate = $daypicker.querySelector('.container[data-date="' + date + '"]')
                if (!$curRangeDate.classList.contains('current'))
                    $curRangeDate.classList.add('picked')
            })


        }

        console.log(firstPick, secondPick)

    }

    function fillFreeDates(dates) {
        for (const [date, times] of Object.entries(dates)) {
            //console.log('CALENDAR DATE : ' + date);
            let $curDate = $daypicker.querySelector('.container[data-date="' + date + '"]')
            // console.log('CUR - ' + $curDate)
            if ($curDate) {
                $curDate.dataset.times = times;
                $curDate.classList.remove('disable');
            }

            $curDate.addEventListener('click', () => {
                choseDay($curDate);
            })
        }
    }

    function refreshBtns(toActive = true) {
        let $calendarBtn = document.querySelector('.calendar-filter-btn');
        let $calendarOrderBtn = document.querySelector('.calendar-filter-order-btn');

        if (toActive == false) {
            console.log("DISABLE")
            if ($calendarBtn)
                $calendarBtn.classList.add('btn-blue--disable')
            if ($calendarOrderBtn)
                $calendarOrderBtn.classList.add('btn-blue--disable')
        } else {
            if ($calendarBtn)
                $calendarBtn.classList.remove('btn-blue--disable')
            if ($calendarOrderBtn)
                $calendarOrderBtn.classList.remove('btn-blue--disable')
        }

    }

    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());

        date.setDate(date.getDate() + 1);

        const dates = [];

        while (date < endDate) {
            let curDate = new Date(date)
            let calendarDay = curDate.getDate() < 10 ? ('0' + curDate.getDate()) : curDate.getDate()
            let calendarMonth = (curDate.getMonth() + 1) < 10 ? ('0' + (curDate.getMonth() + 1)) : (curDate.getMonth() + 1)
            dates.push(curDate.getFullYear() + '-' + calendarMonth + '-' + calendarDay);
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

}
