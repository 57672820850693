let $blogNavs = document.querySelectorAll('.blog__container-nav-addon-item')
let $blogNav = document.querySelector('.nav-sticky')
let $blogNavContainer = document.querySelector('.blog__container--nav')




let $stickyContainer  = document.querySelector('.nav-sticky');

// if ($stickyContainer)
// {
//
// }

if ($blogNavs.length > 0) {
    $firstNav = $blogNavs[0];
    let $sections = Array.prototype.slice.call(document.querySelectorAll('.blog__section'));
    let $phoneSection = document.querySelector('.blog__phone');
    let $navEndSection = document.querySelector('.blog__nav-end');
    let $navEndSectionHeight = parseInt(window.getComputedStyle($navEndSection,null).getPropertyValue("height"));
    let $navHeight = parseInt(window.getComputedStyle($blogNav,null).getPropertyValue("height"));
    let catchSection = 0;
    let absoluteTop = 0;
    let isBottom = false;
    let $blogNavContainerAddon = $blogNavContainer.querySelector('.blog__container-addon')


    //console.log($phoneSectionHeight)

    $sections = $sections.reverse();


    $sections.forEach($section => {
        console.log($section.id);
        console.log($section.getBoundingClientRect().top)

    });

    window.addEventListener('scroll', () => {
        checkCurrentSection()

        let top = $blogNav.getBoundingClientRect().top

        let containerTop = $blogNavContainer.getBoundingClientRect().top
        let bottom = $blogNav.getBoundingClientRect().bottom
        let endBottom = $navEndSection.getBoundingClientRect().bottom
        let scrollTop =window.pageYOffset || document.documentElement.scrollTop;

        let currentTop = (top + scrollTop) - 20;

        console.log('catchSection: ' + catchSection + '\n' +  'endBottom: ' +  endBottom+ '\n' +  'bottom: ' +  bottom + '\n' +  'currentTop: ' +  currentTop + '\n' +  'scrollTop: ' +scrollTop + '\n' +'containerTop' + containerTop);


        if (isBottom == false){
            if (containerTop < -70) {
                console.log('OTKREP CATCH')
                if (!$blogNav.classList.contains('blog__container-nav-addon--fixed')) {
                    $blogNav.classList.add('blog__container-nav-addon--fixed')
                }
            } else {
                console.log('ZAKREP CATCH')

                if ($blogNav.classList.contains('blog__container-nav-addon--fixed')) {
                    $blogNav.classList.remove('blog__container-nav-addon--fixed')
                }

                if (catchSection != 0)
                {
                    return;
                }
            }
        }



        if (endBottom > catchSection && catchSection != 0){
            console.log('ABSOLUTE DE-CATCH')

            $blogNavContainerAddon.style.position = 'relative'
            $blogNav.style.top = 70 + 'px'
            $blogNav.classList.add('blog__container-nav-addon--fixed')
            $blogNav.classList.remove('blog__container-nav-addon--absolute')
            isBottom = false

            return;
        }

        if (endBottom < bottom)
        {
            console.log('ABSOLUTE CATCH')
            if (catchSection == 0)
            {
                catchSection = bottom;
            }
            if (absoluteTop == 0){
                absoluteTop = currentTop
            }

            if ($blogNav.classList.contains('blog__container-nav-addon--fixed')) {
                $blogNavContainerAddon.style.position = 'static'
                $blogNav.style.top = absoluteTop + 'px'
                $blogNav.classList.add('blog__container-nav-addon--absolute')
                $blogNav.classList.remove('blog__container-nav-addon--fixed')
                isBottom = true;
            }
            return;
        }



        return;


    });

    $blogNavs.forEach($blogNav => {
        $blogNav.addEventListener('click', e => {
            changeBlogNavFocus($blogNav);
        })
    })

    function checkCurrentSection() {
        $sections.every($section => {

            if ($section.getBoundingClientRect().top < 70) {
                $curNav = document.querySelector('.blog__container-nav-addon-item[data-to="' + $section.id + '"]');
                if ($curNav) {
                    changeBlogNavFocus($curNav);
                }

                return false;
            }

            return true;
        });
    }

    function changeBlogNavFocus($curNav) {
        $blogNavs.forEach($blogNav => {
            $blogNav.classList.remove('blog__container-nav-addon-item--focus');
        })

        $curNav.classList.add('blog__container-nav-addon-item--focus');

        // setTimeout(()=>{
        //     $curNav.classList.remove('blog__container-nav-addon-item--focus');
        //     $firstNav.classList.add('blog__container-nav-addon-item--focus');
        //
        // }, 300);
    }
}
