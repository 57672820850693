let $modalTip = document.querySelector('.modal-tip');

let activeModalClass = 'modal-tip--active';

if ($modalTip) {
    refreshModalTips($modalTip);

    document.addEventListener('mousemove', e => {

        let x = e.clientX;
        let y = e.clientY;
        let tipRect = $modalTip.getBoundingClientRect();
        if (x < tipRect.left || x > tipRect.right || y < tipRect.top || y > tipRect.bottom) {
            $modalTip.classList.remove(activeModalClass)
        } else {
            console.log('MOUSE_IN')
        }
    })
}

export function refreshModalTips($modalTip) {
    let $modalTipTriggers = document.querySelectorAll('.modal-tip-trigger');

    $modalTipTriggers.forEach($modalTipTrigger => {
        console.log('TRIGGER TIP')

        $modalTipTrigger.addEventListener('mouseenter', e => {
            let x = e.clientX;
            let y = e.clientY;
            activateModalTip($modalTip, $modalTipTrigger.dataset.content, x, y);
            // console.log(triggerRect)

            //activateModalTipByRect($modalTip, $modalTipTrigger.dataset.content, $modalTipTrigger);
        })

        $modalTipTrigger.addEventListener('click', e => {
            let x = e.clientX;
            let y = e.clientY;
            activateModalTip($modalTip, $modalTipTrigger.dataset.content, x, y);
        })

    })
}

export function refreshModalTip($modalTip, $modalTipTrigger) {

    console.log('TRIGGER TIP')

    $modalTipTrigger.addEventListener('mouseenter', e => {
        let x = e.clientX;
        let y = e.clientY;
        activateModalTip($modalTip, $modalTipTrigger.dataset.content, x, y);
    })

    $modalTipTrigger.addEventListener('click', e => {
        let x = e.clientX;
        let y = e.clientY;
        activateModalTip($modalTip, $modalTipTrigger.dataset.content, x, y);
    })

}

export function activateModalTipByRect($modalTip, content, trigger) {
    let maxWidth = Math.min(window.innerWidth, 300);
    $modalTip.innerText = content;
    $modalTip.style.maxWidth = maxWidth + 'px';

    let triggerRect = trigger.getBoundingClientRect();
    let modalTipWidth = $modalTip.offsetWidth;
    let modalTipHeight = $modalTip.offsetHeight;

    let modalTipX = triggerRect.right + modalTipWidth <= window.innerWidth ? triggerRect.right : triggerRect.left - modalTipWidth;
    let modalTipY = trigger.offsetTop + triggerRect.height + modalTipHeight <= window.innerHeight + window.scrollY ? trigger.offsetTop + triggerRect.height : trigger.offsetTop - modalTipHeight;

    console.log(modalTipY);

    // позиционируем модальное окно
    $modalTip.style.left = `${modalTipX}px`;
    $modalTip.style.top = `${modalTipY}px`;
    $modalTip.classList.add(activeModalClass)
}

export function activateModalTip($modalTip, content, x = 0, y = 0) {
    let maxWidth = Math.min(window.innerWidth, 300);


    console.log(y, $modalTip.offsetHeight, (y - 10 - $modalTip.offsetHeight))

    console.log('active', content)
    $modalTip.classList.add(activeModalClass)
    $modalTip.innerText = content;
    $modalTip.style.maxWidth = maxWidth + 'px';

    let currentWidth = $modalTip.offsetWidth

    $modalTip.style.left = Math.max(0, x - currentWidth / 4) + 'px';
    $modalTip.style.top = (y + 28 - $modalTip.offsetHeight) + 'px';

}
