let $tabs = document.querySelectorAll('.tabs');

let bodyActiveClass = 'tabs-body-item--active'
let headActiveClass = 'tabs-head-item--active'


$tabs.forEach($tab => {
    if (!$tab.classList.contains('tabs--disable')){
        let $tabHead = $tab.querySelector('.tabs-head');
        let $tabBody = $tab.querySelector('.tabs-body');

        let $tabBodyItems = $tabBody.querySelectorAll('.tabs-body-item');
        let $tabHeadItems = $tabHead.querySelectorAll('.tabs-head-item')
        $tabHeadItems.forEach($tabHeadItem => {
            let $dataTab = $tabHeadItem.dataset.tab;
            let $currentBody = $tabBody.querySelector('.tabs-body-item[data-tab_to="' + $dataTab + '"]')

            $tabHeadItem.addEventListener('click', e => {
                console.log('TABS CLICK')
                console.log($tabHeadItem)
                if (!$tabHeadItem.classList.contains('more-date-btn')){

                    switchTab($tabBodyItems, $currentBody,  bodyActiveClass)
                    switchTab($tabHeadItems, e.currentTarget,  headActiveClass)
                }
            })



        })
    }
})

document.addEventListener("more-date-calendar-accept", (e) => {
    let $moreDateTabsHead = document.querySelector('.tabs-head-item.more-date-btn');
    let $tab = document.querySelector('.tabs.specialist-order-times');
    let $tabHead = $tab.querySelector('.tabs-head');
    let $tabBody = $tab.querySelector('.tabs-body');

    let $tabBodyItems = $tabBody.querySelectorAll('.tabs-body-item');
    let $tabHeadItems = $tabHead.querySelectorAll('.tabs-head-item')

    let $dataTab = $moreDateTabsHead.dataset.tab;
    let $currentBody = $tabBody.querySelector('.tabs-body-item[data-tab_to="' + $dataTab + '"]')

    switchTab($tabBodyItems, $currentBody,  bodyActiveClass)
    switchTab($tabHeadItems, $moreDateTabsHead,  headActiveClass)
});

function switchTab($items, $currentItem ,activeClass){
    $items.forEach($i=>{
        $i.classList.remove(activeClass);
    })
    $currentItem.classList.add(activeClass)
}
