export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + "; path=/; domain=sense-life.ru" + expires;
}

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getAllCookies() {
    let cookies = {};
    if (document.cookie && document.cookie !== "") {
        let cookiesArr = document.cookie.split(";");
        cookiesArr.forEach(cookie => {
            let [name, value] = cookie.split("=");
            cookies[name.trim()] = decodeURIComponent(value);
        });
    }
    return cookies;
}
