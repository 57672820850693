import 'flowbite';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { locales } from "../../../node_modules/flowbite-datepicker/js/i18n/base-locales.js";
import ru from "../../../node_modules/flowbite-datepicker/js/i18n/locales/ru.js";

Datepicker.locales.ru = ru

const datepickerOptions = {
    language: "ru",
};
const datepickerEl = document.getElementById('datepickerId');

if(datepickerEl){
    const d = new Datepicker(datepickerEl);
    d.setOptions(datepickerOptions);
}


// new Datepicker(datepickerEl, {
//     // options
// });
