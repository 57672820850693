import {getAllCookies, getCookie, setCookie} from "./cookie";
export function getTimezoneParser(url){
    let params = [];

    console.log(url)


    // Разбираем URL и получаем параметры
    let queryString = url.split("?")[1];
    if (queryString) {
        let keyValuePairs = queryString.split("&");
        keyValuePairs.forEach(keyValuePair => {
            let [key, value] = keyValuePair.split("=");
            params[key] = decodeURIComponent(value);
        });
    }

    // Оставляем только UTM-параметры
    let timezoneParam= null
    Object.keys(params).forEach(key => {
        if (key === 'timezone') {
            timezoneParam = params[key];
        }
    });

    return timezoneParam;
}

let timezoneParam = getTimezoneParser(window.location.href);
console.log('timezone', timezoneParam);

if (timezoneParam)
{
    changeTimezone(timezoneParam)
}

let cookie = getCookie('timezone');

console.log('COOKIE_______', cookie);
console.log(getAllCookies())

document.changeTimezone = function changeTimezone(timezone){
    console.log('EVENT: timezone-changed')
    setCookie('timezone', timezone, 30);

    let event = new CustomEvent("timezone-changed");
    document.dispatchEvent(event);
}

export function changeTimezone(timezone){
    console.log('EVENT: timezone-changed')
    setCookie('timezone', timezone, 30);

    let event = new CustomEvent("timezone-changed");
    document.dispatchEvent(event);
}
