import {getAllCookies, getCookie, setCookie} from "./cookie";
import {changeTimezone} from "./timezone-parser";
export function getLocationParams(url) {
    let params = [];

    // Разбираем URL и получаем параметры
    let queryString = url.split("?")[1];
    if (queryString) {
        let keyValuePairs = queryString.split("&");
        keyValuePairs.forEach(keyValuePair => {
            let [key, value] = keyValuePair.split("=");
            params[key] = decodeURIComponent(value);
        });
    }

    // Оставляем только UTM-параметры
    let locationParam= null
    Object.keys(params).forEach(key => {
        if (key === 'global_location') {
            locationParam = params[key];
        }
    });

    return locationParam;
}
let locationParam = getLocationParams(window.location.href);
console.log('location', locationParam);

if (locationParam)
{
    setCookie('global_location', locationParam, 30);

    changeTimezone(3)
}


let cookie = getCookie('global_location');


if (cookie != null && cookie == 'true')
{
    console.log('change location')
    let $globalItems = document.querySelectorAll('.location--global')
    let $locallItems = document.querySelectorAll('.location--local')

    let $globalCb = document.querySelectorAll('.location__checkbox--global')

    $globalCb.forEach($cb=>{
        $cb.checked = true
    })

    $locallItems.forEach($locallItem=>{
        $locallItem.style.cssText  = 'display:none !important';
    })

    $globalItems.forEach($globalItem=>{
        $globalItem.style.cssText  = 'display:flex !important';
    })
}




