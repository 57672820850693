import {getAllCookies, getCookie, setCookie} from "./cookie";

export function getUTMParams(url) {
    let params = [];

    // Разбираем URL и получаем параметры
    let queryString = url.split("?")[1];
    if (queryString) {
        let keyValuePairs = queryString.split("&");
        keyValuePairs.forEach(keyValuePair => {
            let [key, value] = keyValuePair.split("=");
            params[key] = decodeURIComponent(value);
        });
    }

    // Оставляем только UTM-параметры
    let utmParams = [];
    Object.keys(params).forEach(key => {
        if (key.match(/^utm_/)) {
            utmParams[key] = params[key];
        }
    });

    return utmParams;
}

let utmParams = getUTMParams(window.location.href);

Object.keys(utmParams).forEach(key => {
    setCookie(key, utmParams[key], 30);
});


