axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const $phoneVerificationPage = document.querySelector(`[data-phone-verification-page]`);

if ($phoneVerificationPage) {
    phoneVerification({$wrapper: $phoneVerificationPage});
}

export function phoneVerification({
                                      $wrapper,
                                      $startPhone,
                                      MAX_ATTEMPTS = 3,
                                      RECALL_COOLDOWN = 90,
                                      dataPrefix = 'data-phone-verification',
                                      callback = () => {
                                      }
                                  }) {
    let CODE = null;
    let callAttempts = 0;
    let currentFieldIndex = 0;
    let isFirstPhoneOpen = true;

    const $title = $wrapper.querySelector(`[${dataPrefix}-title]`);
    const $info = $wrapper.querySelector(`[${dataPrefix}-info]`);

    const $pinInput = $wrapper.querySelector(`[${dataPrefix}-pin-input]`);
    const $pinFields = Array.from($pinInput.querySelectorAll(`[${dataPrefix}-pin-input-field]`));

    const $phoneDisplay = $wrapper.querySelector(`[${dataPrefix}-phone-display]`);
    const $phoneEdit = $wrapper.querySelector(`[${dataPrefix}-phone-edit]`);
    const $phoneEditBack = $phoneEdit.querySelector('.replace-phone-symbols-back');
    const $phoneEditFace = $phoneEdit.querySelector('.replace-phone-symbols-face');

    const $phoneEditLink = $wrapper.querySelector(`[${dataPrefix}-phone-link-edit]`);
    const $phoneConfirmLink = $wrapper.querySelector(`[${dataPrefix}-phone-link-confirm]`);

    const $callStatusMessage = $wrapper.querySelector(`[${dataPrefix}-status-message]`);
    const $callButton = $wrapper.querySelector(`[${dataPrefix}-call-btn]`);
    const $submitButton = $wrapper.querySelector(`[${dataPrefix}-submit-btn]`);

    init();

    function init() {
        console.log('PhoneVerification Init')

        $phoneDisplay.innerText = $startPhone.value;

        //ОБРАБОТКА ПИНКОД ИНПУТОВ
        $pinInput.addEventListener('click', () => {
            currentFieldIndex = 0;
            $pinInput.focus();

            $pinFields.forEach(field => field.value = '');
            checkPinFields();
        });
        $pinInput.addEventListener('keydown', (event) => {
            if (event.key >= 0 && event.key <= 9) {
                if (currentFieldIndex === $pinFields.length - 1 && $pinFields[currentFieldIndex].value !== '') {
                    return;
                }
                $pinFields[currentFieldIndex].value = event.key;
                currentFieldIndex = Math.min(currentFieldIndex + 1, $pinFields.length - 1);
            } else if (event.key === 'Backspace') {
                if ($pinFields[currentFieldIndex].value === '' && currentFieldIndex > 0) {
                    currentFieldIndex = currentFieldIndex - 1;
                }
                $pinFields[currentFieldIndex].value = '';
            }
            checkPinFields();
            event.preventDefault();
        });
        for (let field of $pinFields) {
            field.addEventListener('focus', () => $pinInput.focus());
        }
        checkPinFields();

        //ОБРАБОТКА ПРОВЕРКИ КОДА
        $submitButton.addEventListener('click', () => {
            const pin = Array.from($pinFields).map(field => field.value).join('');

            if (pin == CODE && CODE != null) {
                $callStatusMessage.style.display = 'none';
                modals.close()
                callback(true);
            } else {
                $callStatusMessage.innerText = 'Неверно указанный код'
                $callStatusMessage.style.display = 'block';
            }
        });

        //ОБРАБОТКА ОБРАТНОГО ОТСЧЁТА
        $callButton.addEventListener('click', () => {
            checkAttempts()
        });

        //ОБРАБОТКА РЕДАКТИРОВАНИЯ ТЕЛЕФОНА
        $phoneEditLink.addEventListener('click', () => {
            $phoneEditFace.value = $phoneDisplay.textContent;
            $phoneDisplay.hidden = true;
            $phoneEdit.style.display = 'flex';
            $phoneEditLink.hidden = true;
            $phoneConfirmLink.hidden = false;
            $phoneEdit.focus();
        });
        $phoneConfirmLink.addEventListener('click', () => {
            if ($phoneEditBack.value.length === 10) {
                $phoneDisplay.textContent = $phoneEditFace.value;
                $phoneDisplay.hidden = false;
                $phoneEdit.style.display = 'none';
                $phoneEditLink.hidden = false;
                $phoneConfirmLink.hidden = true;
                $callStatusMessage.style.display = 'none';

                $startPhone.value = $phoneEditFace.value;
            } else {
                $callStatusMessage.innerText = 'Неверно указанный номер'
                $callStatusMessage.style.display = 'block';
            }
        });


    }

    function openModal() {
        $phoneDisplay.innerText = $startPhone.value;
        modals.close()
        modals.open($wrapper.id)
        checkAttempts();
    }

    function checkPinFields() {
        const isFilled = Array.from($pinFields).every(field => field.value.length === 1);
        if (isFilled) {
            $submitButton.classList.remove('btn--disable');
        } else {
            $submitButton.classList.add('btn--disable');
        }
    }

    function checkAttempts() {

        if (callAttempts == (MAX_ATTEMPTS - 1))
        {
            $title.innerText = "Введите последние 4 цифры номера"
            $info.innerText = "Мы позвоним на указанный номер, отвечать не нужно. Введите только последние 4 цифры входящего номера."

            callAttempts++;
            startCountdown(RECALL_COOLDOWN, 'звонок'); // начать отсчет
            sendCall();
        }
        else if (callAttempts < MAX_ATTEMPTS) {
            callAttempts++;
            startCountdown(RECALL_COOLDOWN); // начать отсчет
            sendSms();
        } else {
            modals.close()
            modals.open('phone-problem')
            addToStopList();
        }
    }

    function startCountdown(seconds, entity = 'смс') {
        $callButton.classList.add('modal__header-subtext');
        $callButton.classList.remove('modal__header-text--link', 'link');
        $callButton.style.pointerEvents = 'none';

        const intervalId = setInterval(() => {
            seconds -= 1;
            $callButton.textContent = `Отправить ${entity} ещё раз через ${seconds} секунд`;

            if (seconds <= 0) {
                clearInterval(intervalId);
                resetCallButton();
            }
        }, 1000);
    }

    function resetCallButton() {
        $callButton.textContent = 'Отправить еще раз';
        $callButton.classList.remove('modal__header-subtext');
        $callButton.classList.add('modal__header-text--link', 'link');
        $callButton.style.pointerEvents = '';
    }

    function sendSms() {
        const phone = $phoneDisplay.textContent.replace(/[^0-9]+/g, ''); // очистка номера от ненужных символов

        axios.post('/sms/message/send', {phone: phone})
            .then(response => {
                console.log(response.data)

                if (response.data.status !== "OK") {
                    console.log('отправка смс не может быть выполнена: ' + response.data['status_text']);
                    modals.close()
                    modals.open('phone-problem')
                    return;
                }

                let phoneNumbers = Object.keys(response.data.sms);
                if (phoneNumbers.length <= 0) {
                    console.log('Нет номеров в объекте sms');
                    modals.close()
                    modals.open('phone-problem')
                    return;
                }

                let firstPhoneNumber = phoneNumbers[0];
                let status = response.data.sms[firstPhoneNumber].status;
                console.log(`Статус для номера ${firstPhoneNumber}: ${status}`);

                if (status !== "OK") {
                    modals.close()
                    modals.open('phone-problem')
                    return;
                }

                CODE = response.data.code;
            })
            .catch(error => {
                console.log('Произошла ошибка при отправке сообщения.');
                modals.close()
                modals.open('phone-problem')
                console.log(error)
            });
    }

    function sendCall() {
        const phone = $phoneDisplay.textContent.replace(/[^0-9]+/g, ''); // очистка номера от ненужных символов

        axios.post('/sms/call/send', {phone: phone})
            .then(response => {
                console.log(response.data)

                if (response.data.status !== "OK") {
                    console.log('Отправка звонка не может быть выполнена: ' + response.data['status_text']);
                    modals.close()
                    modals.open('phone-problem')
                    return;
                }

                CODE = response.data.code;
            })
            .catch(error => {
                console.log('Произошла ошибка при отправке сообщения.');
                modals.close()
                modals.open('phone-problem')
                console.log(error)
            });
    }

    function addToStopList() {
        const phone = $phoneDisplay.textContent.replace(/[^0-9]+/g, ''); // очистка номера от ненужных символов

        axios.post('/sms/stoplist/add', {phone: phone, reason: 'Большое количество запросов'})
            .then(response => {
                console.log(response.data)

                if (response.data.status !== "OK") {
                    alert('Ошибка при блокировке номера телефона.');
                }

            })
            .catch(error => {
                alert('Ошибка при отправке запроса на блокировку номера телефона.');
            });
    }

    return {
        openModal: openModal
    };
}
