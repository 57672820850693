let $showMoreBtn = document.querySelector('.blog-list-btn-more');
if ($showMoreBtn)
{
    let urlHash = window.location.hash;
    let totalItems = $showMoreBtn.dataset.total;
    let takeItems = $showMoreBtn.dataset.take;

    let urlHashPage = urlHash.split('-');
    urlHashPage = urlHashPage[urlHashPage.length - 1];
    let currentPage = urlHashPage == '' ? 1 : urlHashPage;

    console.log(urlHash);
    console.log('page: ' + currentPage);
    console.log('total: ' + totalItems);
    console.log('take: ' + takeItems);

    if ((currentPage * takeItems) >=  totalItems){
        currentPage = 1;
    }


    if (totalItems > 8){
       // $showMoreBtn.setAttribute('href', '#blog-list-page-2');

        $showMoreBtn.addEventListener('click', e=>{
            currentPage++;
            showMoreBlogs(currentPage);

        });
        $showMoreBtn.style.display = 'flex'

        for (let i = 0; i < currentPage; i++) {
            showMoreBlogs(i + 1);
        }
    }

    function showMoreBlogs(pageNum){
        console.log('open page: ' + pageNum);

        if (pageNum != 1){
            window.location.hash = '#blog-list-page-' + pageNum;
        }

        //$showMoreBtn.setAttribute('href', '#blog-list-page-' + (pageNum + 1));

        $moreBlogList = document.querySelector('#blog-list-page-' + pageNum)
        $moreBlogList.style.display = 'grid'

        if ((currentPage * takeItems) >=  totalItems){
            $showMoreBtn.style.display = 'none'
        }

    }
}
